import { useTranslation } from "react-i18next";
import { DisclaimerProps } from "../../types";
import styles from "./Disclaimer.module.scss";



function Disclaimer({isPrinting} : DisclaimerProps) {
  const { t } = useTranslation();

  return (
    <div 
      className={styles.disclaimer} 
      style={!isPrinting ? { marginTop: "16px", marginBottom: "-48px"} : {marginBottom: "2px", marginTop: 'auto'}}
    >
      <b
        style={!isPrinting ? { fontSize: "10px" } : {fontSize: "8px"}}
      >{t("disclaimer.title")}</b>
      <span
        style={!isPrinting ? { fontSize: "10px" } : {fontSize: "8px"}}
      >
        {t("disclaimer.content", {year: new Date().getFullYear()})}
      </span>  
    </div>
  );
}

export default Disclaimer;