import { useTranslation } from "react-i18next";
import styles from "./TotalItems.module.scss";

type Props = {
  totalItems: number
};

function TotalItems({ totalItems }: Props) {

  const {t} = useTranslation();

  return (
      <span className={styles.container}>
        {`${totalItems} ${totalItems > 1 ? t("general.items"): t("general.item")}`} 
      </span>
  );
}

export default TotalItems;
