import i18next from "i18next";
import * as yup from "yup";
import { GeneticsPoultry } from "../enums/genetics.enum";
import { SexPoultry } from "../enums/sex.enum";

export interface AnimalStepPoultryForm {
  speciesGroup?: any;
  speciesId?: string;
  age: number;
  problemArea?: string | null;
  specificProblemArea?: string | null;
  clinicalChallenges?: string | null;
  clinicalChallengesUnit?: string | null;
  flockPerformanceAtSample?: string | null;
  flockPerformanceAtSampleUnit?: string | null;
  sexPoultry: SexPoultry;
  geneticPoultry: string | null;
  breedAndStrain: string | null;
}

export const getAnimalStepPoultryFormSchema = () => {
  return yup.object({
    speciesGroup: yup.mixed().notRequired(),
    speciesId: yup.string().required().label(i18next.t("sample-request-create-poultry.sub-species")),
    age: yup.number().required().label(i18next.t("sample-request-create-poultry.age")),
    problemArea: yup.string().required().label(i18next.t("sample-request.problem-area")),
    specificProblemArea: yup.string().notRequired().label(i18next.t("sample-request.specific-problem-area")),
    clinicalChallenges: yup.string().notRequired().label(i18next.t("sample-request.clinical-challenges")),
    clinicalChallengesUnit: yup.string().notRequired().label(i18next.t("sample-request.clinical-challenges-unit")),
    flockPerformanceAtSample: yup.string().notRequired().label(i18next.t("sample-request.flock-performance-at-sample")),
    flockPerformanceAtSampleUnit: yup.string().notRequired().label(i18next.t("sample-request.flock-performance-at-sample-unit")),
    sexPoultry: yup.mixed<SexPoultry>().oneOf(Object.values(SexPoultry)).required().label(i18next.t("sample-request.sex-poultry")),
    geneticPoultry: yup.mixed<GeneticsPoultry>().oneOf(Object.values(GeneticsPoultry)).required().label(i18next.t("sample-request.genetics")),
    breedAndStrain: yup.string().required().label(i18next.t("sample-request-create-poultry.breed-and-strain")),
  });
};
