import i18next from "i18next";
import * as yup from "yup";
import { SpeciesGroup } from "../API";
import { HousingPoultry } from "../enums/housing.enum";
import { ProductionSystemPoultry } from "../enums/productionSystem.enum";

export interface FarmStepPoultryForm {
  farmId: string;
  speciesGroup: SpeciesGroup;
  barn?: string | null;
  houseNumber?: string | null;
  housingPoultry?: HousingPoultry;
  productionPoultry?: ProductionSystemPoultry | null;
  productionPoultryOther?: string;
}

export const getFarmStepFormSchema = () => {
  return yup.object({
    customerId: yup.string().required().label(i18next.t("sample-request.customer")),
    farmId: yup.string().required().label(i18next.t("sample-request.farm")),
    speciesGroup: yup.mixed<SpeciesGroup>().oneOf(Object.values(SpeciesGroup)).required(),
    barn: yup.string().notRequired().label(i18next.t("sample-request.barn")),
    houseNumber: yup.string().notRequired().label(i18next.t("sample-request.house-number")),
    housingPoultry: yup
      .mixed<HousingPoultry>()
      .label(i18next.t("sample-request.housing"))
      .when("speciesGroup", {
        is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Poultry,
        then: (schema) => schema.oneOf(Object.values(HousingPoultry)).required()
      }),
    productionPoultry: yup
    .mixed<ProductionSystemPoultry>()
    .label(i18next.t("sample-request.production"))
    .when("speciesGroup", {
      is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Poultry,
      then: (schema) => schema.required().oneOf(Object.values(ProductionSystemPoultry))
      }),
    productionPoultryOther: yup
      .string()
      .label(i18next.t("sample-request.production-other"))
      .when("productionPoultry", {
        is: (productionSystemPoultry: ProductionSystemPoultry) => productionSystemPoultry === ProductionSystemPoultry.Other,
        then: (schema) => schema.required()
      })
  });
};
