import i18next from "i18next";
import * as yup from "yup";

export interface FilterCustomerForm {
  searchQuery: string;
}

export const getFilterCustomerDetailsFormSchema = () => {
  return yup
    .object({
      query: yup.string().optional().label(i18next.t("general.search")),
      page: yup.number().required().min(0).default(0),
      limit: yup.number().required().min(1).default(10),
    })
    .required();
};
