export enum Sex {
  Male = "Male",
  Female = "Female",
  Mixed = "Mixed"
}

export enum SexPoultry {
  Male = "Male",
  Female = "Female",
  AsHatched = "AsHatched"
}