import { useTranslation } from "react-i18next";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import { AnalysisIcon, ComputerIcon, DeliveryIcon, LabIcon, ResultsIcon } from '../../assets/icons';
import { VideoProps } from "../../types";
import styles from './UserGuide.module.scss';
import DetailsSection from './components/detailsSection/DetailsSection';
import Step from './components/step/Step';

const UserGuide = () => {
  const {t} = useTranslation();
  const steps = [
    { icon: ComputerIcon, titleKey: "home.steps.one.title", descriptionKey: "home.steps.one.description" },
    { icon: DeliveryIcon, titleKey: "home.steps.two.title", descriptionKey: "home.steps.two.description" },
    { icon: AnalysisIcon, titleKey: "home.steps.three.title", descriptionKey: "home.steps.three.description" },
    { icon: LabIcon, titleKey: "home.steps.four.title", descriptionKey: "home.steps.four.description" },
    { icon: ResultsIcon, titleKey: "home.steps.five.title", descriptionKey: "home.steps.five.description" },
  ];

  const details = [
    { headerKey: "home.details.one.header", bullets: ["home.details.one.bullets.one", "home.details.one.bullets.two"] },
    { headerKey: "home.details.two.header", bullets: ["home.details.two.bullets.one", "home.details.two.bullets.two"] },
    { headerKey: "home.details.three.header", bullets: ["home.details.three.bullets.one", "home.details.three.bullets.two", "home.details.three.bullets.three"] },
    { headerKey: "home.details.four.header", bullets: ["home.details.four.bullets.one"] },
  ];

  const videos = [
    {url: "home.intro-video.url", title: "home.intro-video.title", poster: "home.intro-video.poster"},
  ];

  return (
    <>
      <div className={styles["use-guide"]}>
        {steps.map((step, index) => (
          <Step
            key={index}
            icon={step.icon}
            titleKey={step.titleKey}
            descriptionKey={step.descriptionKey}
          />
        ))}
      </div>
      <div className={styles["use-guide__description"]}>
        <div className={styles["use-guide__details"]}>
          {details.map((detail, index) => (
            <DetailsSection key={index} headerKey={detail.headerKey} bullets={detail.bullets} />
          ))}
        </div>
        <Swiper 
          spaceBetween={50} 
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          modules={[ Pagination ]}
          style={{ width: "100%", height: "260px" }}
        >   
          {
          videos.map((video: VideoProps, index: number) => (
          <SwiperSlide key={index}>
          <video 
            src={t(video.url)} 
            title={t(video.title)} 
            controls 
            poster={t(video.poster)} 
            preload="none" 
            controlsList="nodownload" />
          </SwiperSlide>))
          }
        </Swiper>
      </div>
    </>
  );
};

export default UserGuide;
