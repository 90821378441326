import { useTranslation } from "react-i18next";
import { UserGuiderDetailsProps } from "../../../../types";
import styles from "./DetailsSection.module.scss";

const DetailsSection = ({ headerKey, bullets }: UserGuiderDetailsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <span className={styles["user-guide__details-header"]}>
        {t(headerKey)}
      </span>
      <ul className={styles["user-guide__details-items"]}>
        {bullets.map((bullet, index) => (
          <li dangerouslySetInnerHTML={{ __html: t(bullet) }}></li>
        ))}
      </ul>
    </>
  );
};

export default DetailsSection;