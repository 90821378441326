import i18next from "i18next";
import * as yup from "yup";
import { D3Unit } from "../enums/d3Unit.enum";
import { OHUnit } from "../enums/ohUnit.enum";
import { PhytaseUnit } from "../enums/phytaseUnit.enum";
import { TypeOfDiet } from "../enums/typeOfDiet.enum";

export interface DietStepPoultryForm {
  d3Level?: number | null;
  ohLevel?: number | null;
  caLevel?: number | null;
  caTotal?: number | null;
  pTotal?: number | null;
  phytase?: number | null;
  phytaseUnit?: string | null;
  d3Unit?: string | null;
  ohUnit?: string | null;
  pAvailable?: number | null;
  feedingPhase?: string | null;
  typeOfDiet?: string | null;
  otherTypeOfDiet?: string | null;
}

export const getDietStepFormSchema = () => {
  return yup.object({
    d3Level: yup
      .number()
      .required()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.d3-level") }))
      .min(0)
      .label(i18next.t("sample-request.d3-level")),
    ohLevel: yup
      .number()
      .required()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.oh-level") }))
      .min(0)
      .label(i18next.t("sample-request.oh-level")),
    feedingPhase: yup
      .string()
      .required()
      .label(i18next.t("sample-request-create-poultry.feeding-phase")),
    typeOfDiet: yup
      .mixed<TypeOfDiet>()
      .notRequired()
      .label(i18next.t("sample-request.type-of-diet")),
    otherTypeOfDiet: yup
      .string()
      .when('typeOfDiet', {
        is: (typeOfDiet: string) => typeOfDiet?.toLowerCase() == TypeOfDiet.OTHER.toLowerCase(),
        then: (schema) => schema.required().label("Type of diet specification")
      }),
    caLevel: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.ca-level") }))
      .min(0)
      .label(i18next.t("sample-request.ca-level")),
    caTotal: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.ca-total") }))
      .min(0)
      .label(i18next.t("sample-request.ca-total")),
    pTotal: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.p-total") }))
      .min(0)
      .label(i18next.t("sample-request.p-total")),
    pAvailable: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.p-available") }))
      .min(0)
      .label(i18next.t("sample-request.p-available")),
    phytase: yup
      .number()
      .notRequired()
      .typeError(i18next.t("form.errors.number", { label: i18next.t("sample-request.phytase") }))
      .min(0)
      .label(i18next.t("sample-request.phytase")),
    phytaseUnit: yup
      .mixed<PhytaseUnit>()
      .label(i18next.t("sample-request.phytase-unit"))
      .when("phytase", {
        is: (phytase: number) => phytase && phytase >= 0,
        then: (schema) => schema.oneOf(Object.values(PhytaseUnit)).required()
      }),
    d3Unit: yup
      .mixed<D3Unit>()
      .label(i18next.t("sample-request.d3-unit"))
      .when("d3Level", {
        is: (d3Level: number) => d3Level && d3Level >= 0,
        then: (schema) => schema.oneOf(Object.values(D3Unit)).required()
      }),
    ohUnit: yup
      .mixed<OHUnit>()
      .label(i18next.t("sample-request.oh-unit"))
      .when("ohLevel", {
        is: (ohLevel: number) => ohLevel && ohLevel >= 0,
        then: (schema) => schema.oneOf(Object.values(OHUnit)).required()
      })
  });
};
