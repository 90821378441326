import i18next from "i18next";
import * as yup from "yup";
import { PurposeOfAnalysis } from "../../models/enums/purposeOfAnalysis.enum";
import { checkCardId } from "../../services/sample.service";

const checkCardUsed = async (value: string, values: yup.TestContext<yup.AnyObject>): Promise<boolean> => {
  const isAvailable = await checkCardId(value);
  if (!isAvailable) {
    values.createError({ path: "card_used" });
    return false;
  } else {
    return true;
  }
};

export interface SamplesStepPoultryForm {
  collectionDate: string;
  purposeOfAnalysis: string;
  remarks?: string | null;
  cards?: {
    card: number;
    animal?: string | null;
    parity?: string | null;
    problemAnswer?: string | null;
  }[];
}

export const getSamplesStepFormSchema = () => {
  return yup.object({
    collectionDate: yup.string().required().label(i18next.t("sample-request.collection-date")),
    purposeOfAnalysis: yup.mixed<PurposeOfAnalysis>().required().label(i18next.t("general.purpose-of-analysis.label")),
    remarks: yup.string().notRequired().label(i18next.t("sample-request.remarks")),
    cards: yup
      .array()
      .of(
        yup.object().shape({
          card: yup
            .number()
            .min(21)
            .required()
            .label(i18next.t("sample-request.card"))
            .test("card_used", i18next.t("sample-request-create.cards-used"), async (value, values) => {
              const verified = await checkCardUsed(value.toString(), values);
              return verified as boolean;
            }),
          animal: yup.string().notRequired().label(i18next.t("sample-request.animal")),
          parity: yup.string().notRequired().label(i18next.t("sample-request.parity")),
          problemAnswer: yup.string().notRequired().label(i18next.t("general.this"))
        })
      )
      .uniqueProperty(i18next.t("sample-request-create.cards-duplicate"), "cards", "card")
      .min(1, i18next.t("sample-request-create.cards-empty"))
  });
};
