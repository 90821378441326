import { ReactNode } from "react";
import useBreakpoint, { Breakpoints } from "../../hooks/breakpoint.hook";
import TotalItems from "../totalItems/TotalItems";
import styles from "./PageHeader.module.scss";

type Props = {
  header: string;
  description?: string;
  actionsSide?: ReactNode;
  children?: ReactNode;
  videoUrl?: string;
  videoPoster?: string;
  videoTitle?: string;
  totalItems?: number;
};

function PageHeader({ header, description, children, actionsSide, videoUrl, videoPoster, videoTitle, totalItems }: Props) {
  const breakpoint = useBreakpoint();

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.title}>
          <h2 className={styles.header} dangerouslySetInnerHTML={{__html: header}}/>
          { totalItems !== undefined && totalItems>=0 ? (
            <div className={styles["title__total-items"]}>
              <TotalItems totalItems={totalItems} />
            </div>
          ) : null }
        </div>
        {!!description || !!videoUrl || !!children ? (
          <div className={styles.content}>
            {description ? <span dangerouslySetInnerHTML={{ __html: description }}></span> : null}
            {videoUrl && (breakpoint === Breakpoints.xs || breakpoint === Breakpoints.sm) ? (
              <video src={videoUrl} title={videoTitle} controls poster={videoPoster} preload="none" controlsList="nodownload"></video>
            ) : null}
            {children ? <div className={`${styles.actions} ${styles["actions--description"]}`}>{children}</div> : null}
          </div>
        ) : null}
      </div>
      <div className={styles.side}>
        {videoUrl && breakpoint !== Breakpoints.xs && breakpoint !== Breakpoints.sm ? (
          <video src={videoUrl} title={videoTitle} controls poster={videoPoster} preload="none" controlsList="nodownload"></video>
        ) : null}
        {actionsSide ? <div className={`${styles.actions}`}>{actionsSide}</div> : null}
      </div>
    </div>
  );
}

export default PageHeader;
