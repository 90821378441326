import * as yup from "yup";
import i18next from "i18next";
import { ComparisonType } from "../API";

export interface FilterSampleRequestForm {
  searchQuery: string;
  collectionDateStart: string;
  collectionDateEnd: string;
  phaseIds: string;
  resultsType: ComparisonType;
  resultsValue: number;
}

export const getFilterDashboardSampleRequestFormSchema = () => {
  return yup
    .object({
      searchQuery: yup.string().optional().label(i18next.t("general.search")),
      collectionDateStart: yup.string().optional().label(i18next.t("sample-request.collection-date-start")),
      collectionDateEnd: yup.string().optional().label(i18next.t("sample-request.collection-date-end")),
      phaseIds: yup.string().optional().label(i18next.t("sample-request.phase")),
      resultsType: yup.mixed<ComparisonType>().optional().label(i18next.t("sample-request.results-type")),
      resultsValue: yup.number().optional().positive().label(i18next.t("sample-request.results-value")),
      groupId: yup.string().optional().label(i18next.t("sample-request.species")),
      speciesId: yup.string().optional().label(i18next.t("sample-request-create-poultry.sub-species")),
    })
    .required();
};
