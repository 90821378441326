import { useTranslation } from "react-i18next";
import { BoxPlotStatisticalData } from "../../types";
import styles from "./StatsTable.module.scss";

type Props = {
  data: BoxPlotStatisticalData[];
}

function ResultList({ data }: Props) {
  const { t } = useTranslation();
  const headers = [
    'dashboard.stats-table.maximum',
    'dashboard.stats-table.minimum',
    'dashboard.stats-table.median',
    'dashboard.stats-table.first-quartile',
    'dashboard.stats-table.third-quartile',
    'dashboard.stats-table.lower-fence',
    'dashboard.stats-table.upper-fence',
  ];

  return (
    <table 
      className={styles["stats-table"]}
      data-testid="stats-table"
    >
      <thead 
        className={styles["stats-table__head"]}
        data-testid="stats-table-head"
      >
        <tr>
          <th>
            Stat
          </th>
          {data.map((box, index) => (
            <th
              key={index}
            >
              <div
                className={styles["stats-table__head__content"]}
              >
                <div
                  style={{ backgroundColor: `${box.color}` }}
                  className={styles["stats-table__head__content__color-dot"]}
                  data-testid="color-dot"
                />
                <span
                  className={styles["stats-table__head__content__name"]}
                  title={box.name}
                  data-testid="box-name"
                >
                  {box.name}
                </span>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles["stats-table__body"]}>
        {headers.map((header, rowIndex) => (
          <tr key={rowIndex}>
            <td
              title={t(header)}
            >
              {t(header)}
            </td>
            {data.map((box, colIndex) => {
              const values = [
                box.maximum,
                box.minimum,
                box.median,
                box.firstQuartile,
                box.thirdQuartile,
                box.lowerFence,
                box.upperFence,
              ];
              return (
                <td
                  className={styles["stats-table__body__data-cell"]}
                  key={`${rowIndex}-${colIndex}`}
                  data-testid="data-cell"
                >
                  {!Number.isNaN(values[rowIndex]) ? values[rowIndex].toFixed(2) : '-'}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ResultList;
