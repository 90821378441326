/* eslint-disable @typescript-eslint/no-explicit-any */
import { DsmSelectOption, ProgressStep } from "@dsm-dcs/design-system";
import { DsmButton, DsmGrid, DsmIcon, DsmInput, DsmLoadingIndicator, DsmProgressSteps } from "@dsm-dcs/design-system-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../../../components/form/dateInput/DateInput";
import Input from "../../../components/form/input/Input";
import NumberInput from "../../../components/form/numberInput/NumberInput";
import RadioButtonGroup from "../../../components/form/radioButtonGroup/RadioButtonGroup";
import Select from "../../../components/form/select/Select";
import TextArea from "../../../components/form/textArea/TextArea";
import PageHeader from "../../../components/pageHeader/PageHeader";
import QrModal, { QrModelRef } from "../../../components/qrModal/QrModal";
import { AuthContext } from "../../../contexts/auth.context";
import { useLayout } from "../../../contexts/layout.context";
import { calculateOhLevelIUKG, calculateTotalVitamineD3 } from "../../../helpers/sampleData.helper";
import useBreakpoint, { Breakpoints } from "../../../hooks/breakpoint.hook";
import { BaseLocation, SpeciesGroup } from "../../../models/API";
import { ClinicalChallenges } from "../../../models/enums/clinicalChallenges.enum";
import { D3Unit } from "../../../models/enums/d3Unit.enum";
import { FlockPerformanceAtSample } from "../../../models/enums/flockPerformanceAtSample.enum";
import { GeneticsPoultry } from "../../../models/enums/genetics.enum";
import { HousingPoultry } from "../../../models/enums/housing.enum";
import { OHUnit } from "../../../models/enums/ohUnit.enum";
import { PhytaseUnit } from "../../../models/enums/phytaseUnit.enum";
import { ProblemArea } from "../../../models/enums/problemArea.enum";
import { ProductionSystemPoultry } from "../../../models/enums/productionSystem.enum";
import { PurposeOfAnalysis } from "../../../models/enums/purposeOfAnalysis.enum";
import { Role } from "../../../models/enums/role.enum";
import { SexPoultry } from "../../../models/enums/sex.enum";
import { specificProblemArea } from "../../../models/enums/specificProblemArea.enum";
import { SubSpeciesPoultry } from "../../../models/enums/subSpecies.enum";
import { TypeOfDiet } from "../../../models/enums/typeOfDiet.enum";
import { YesNoUnknown } from "../../../models/enums/yesNo";
import { getAnimalStepPoultryFormSchema } from "../../../models/forms/animal-step-poultry-form.model";
import { getDietStepFormSchema } from "../../../models/forms/diet-step-poultry-form.model";
import { getFarmStepFormSchema } from "../../../models/forms/farm-step-poultry-form.model";
import { getSamplesStepFormSchema } from "../../../models/forms/samples-step-poultry-form.model";
import { routeTypes } from "../../../routes";
import { getLocationsForCustomer } from "../../../services/location.service";
import { convertPhasesForSelect, getPhase, getSpeciesForSelect } from "../../../services/metaData.service";
import { createSampleRequestPoultry } from "../../../services/sample.service";
import { getCustomersForSelect } from "../../../services/user.service";
import ConfirmModal from "./confirmModal/confirmModal";
import styles from "./Create.module.scss";

function SampleRequestPoultryCreate() {
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs } = useLayout();
  const breakpoint = useBreakpoint();
  const { customer, role } = useContext(AuthContext);
  const { setToast } = useLayout();

  const [steps, setSteps] = useState<ProgressStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [farmStepSchema] = useState(getFarmStepFormSchema());
  const {
    watch: farmStepWatch,
    setValue: farmStepSetValue,
    resetField: farmStepResetField,
    control: farmStepControl,
    trigger: farmStepTrigger,
    getValues: farmStepGetValues,
    formState: { errors: farmStepErrors, isValid: isFarmStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(farmStepSchema)
  });
  const [isLoadingFarms, setIsLoadingFarms] = useState<boolean>(false);
  const customerId = farmStepWatch("customerId");
  const farmId = farmStepWatch("farmId");
  const productionPoultry = farmStepWatch("productionPoultry");
  const [customerOptions, setCustomerOptions] = useState<DsmSelectOption[]>([]);
  const [farms, setFarms] = useState<BaseLocation[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<BaseLocation | null>(null);
  const [farmOptions, setFarmOptions] = useState<DsmSelectOption[]>([]);
  const [housingPoultryOptions, setHousingPoultryOptions] = useState<DsmSelectOption[]>([]);
  const [productionSystemPoultryOptions, setProductionSystemPoultryOptions] = useState<DsmSelectOption[]>([]);

  const [speciesOptions, setSpeciesOptions] = useState<DsmSelectOption[]>([]);
  const [isLoadingSpecies, setIsLoadingSpecies] = useState<boolean>(false);
  const [isReproduction] = useState<boolean>(false);
  const [clinicalChallengesOptions, setClinicalChallengesOptions] = useState<DsmSelectOption[]>([]);
  const [flockPerformanceAtSampleOptions, setFlockPerformanceAtSampleOptions] = useState<DsmSelectOption[]>([]);
  const [problemAreaOptions, setProblemAreaOptions] = useState<DsmSelectOption[]>([]);
  const [subSpeciesOptions, setSubSpeciesOptions] = useState<DsmSelectOption[]>([]);
  const [typeOfDietOptions, setTypeOfDietOptions] = useState<DsmSelectOption[]>([]);
  const [specificProblemAreaOptions, setSpecificProblemAreaOptions] = useState<DsmSelectOption[]>([]);
  
  const [sexOptions, setSexOptions] = useState<DsmSelectOption[]>([]);
  const [geneticsOptions, setGeneticsOptions] = useState<DsmSelectOption[]>([]);
  const [animalStepSchema] = useState(getAnimalStepPoultryFormSchema());
  const {
    watch: animalStepWatch,
    setValue: animalStepSetValue,
    resetField: animalStepResetField,
    control: animalStepControl,
    trigger: animalStepTrigger,
    getValues: animalStepGetValues,
    formState: { errors: animalStepErrors, isValid: isAnimalStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(animalStepSchema),
    defaultValues: {
      sexPoultry: SexPoultry.AsHatched,
    }
  });
  const speciesId = animalStepWatch("speciesId");
  const problemAreaValue = animalStepWatch("problemArea");
  const [testReasonProblem, setTestReasonProblem] = useState<string | null>(null);
  const flockPerformanceAtSample = animalStepWatch("flockPerformanceAtSample");
  const clinicalChallenge = animalStepWatch("clinicalChallenges");

  const [phytaseUnitOptions, setPhytaseUnitOptions] = useState<DsmSelectOption[]>([]);
  const [d3UnitOptions, setd3UnitOptions] = useState<DsmSelectOption[]>([]);
  const [purposeOfAnalysisOptions, setPurposeOfAnalysisOptions] = useState<DsmSelectOption[]>([]);
  const [ohUnitOptions, setOhUnitOptions] = useState<DsmSelectOption[]>([]);
  const [feedingPhaseOptions, setFeedingPhaseOptions] = useState<DsmSelectOption[]>([]);
  const [dietStepFormSchema] = useState(getDietStepFormSchema());
  const {
    watch: dietStepWatch,
    control: dietStepControl,
    trigger: dietStepTrigger,
    getValues: dietStepGetValues,
    formState: { errors: dietStepErrors, isValid: isDietStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(dietStepFormSchema),
    defaultValues: {
      phytaseUnit: PhytaseUnit.FTU,
      d3Unit: D3Unit.UGKGFEED,
      ohUnit: OHUnit.UGKGFEED
    }
  });

  const ohLevel = dietStepWatch("ohLevel");
  const d3Level = dietStepWatch("d3Level");
  const typeOfDiet = dietStepWatch("typeOfDiet");

  const qrScannerRef = useRef<QrModelRef | null>(null);
  const [samplesStepFormSchema] = useState(getSamplesStepFormSchema());
  const {
    control: samplesStepControl,
    trigger: samplesStepTrigger,
    getValues: samplesStepGetValues,
    formState: { errors: samplesStepErrors, isValid: isSamplesStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(samplesStepFormSchema),
    defaultValues: {
      cards: [{}]
    }
  });
  const {
    fields: samplesFields,
    remove: removeSample,
    append: appendSample
  } = useFieldArray({ name: "cards", control: samplesStepControl });
  const [yesNoUnknownOptions, setYesNoUnknownOptions] = useState<DsmSelectOption[]>([]);

  useEffect(() => {
    setPageTitle(t("sample-request-create-poultry.page.title"));
    setCrumbs([{ title: t("sample-request-create-poultry.page.title"), type: routeTypes.sampleRequestPoultryCreate }]);
    initData();
  }, []);

  useEffect(() => {
    setSteps((steps) => {
      steps[0].completed = isFarmStepValid;
      steps[1].completed = isFarmStepValid && isAnimalStepValid;
      steps[1].disabled = !isFarmStepValid;
      steps[2].completed = isFarmStepValid && isAnimalStepValid && isDietStepValid;
      steps[2].disabled = !isFarmStepValid || !isAnimalStepValid || !isDietStepValid;
      steps[3].completed = isFarmStepValid && isAnimalStepValid && isDietStepValid && isSamplesStepValid;
      steps[3].disabled = !isFarmStepValid || !isAnimalStepValid || !isDietStepValid || !isSamplesStepValid;
      return steps;
    });
  }, [isFarmStepValid, isAnimalStepValid, isDietStepValid, isSamplesStepValid]);

  useEffect(() => {
    handleCustomerChange();
  }, [customerId]);

  useEffect(() => {
    if (farmId) {
      handleLocationChange();
    }
  }, [farmId]);

  useEffect(() => {
    handleProblemAreaChange();
  }, [problemAreaValue]);

  useEffect(() => {
    handleSpeciesChange();
  }, [speciesId]);


  useEffect(() => {
    if (speciesOptions && speciesOptions.length === 1) {
      animalStepSetValue("speciesGroup", speciesOptions[0].value as SpeciesGroup);
    } else {
      animalStepResetField("speciesGroup");
    }
  }, [speciesOptions]);

  const initData = async () => {
    setSteps([
      {
        id: "1",
        completed: false,
        title: t("sample-request-create-poultry.farm-step.step.header"),
        description: t("sample-request-create-poultry.farm-step.step.description")
      },
      {
        id: "2",
        completed: false,
        title: t("sample-request-create-poultry.animal-step.step.header"),
        description: t("sample-request-create-poultry.animal-step.step.description")
      },
      {
        id: "3",
        completed: false,
        title: t("sample-request-create-poultry.diet-step.step.header"),
        description: t("sample-request-create-poultry.diet-step.step.description")
      },
      {
        id: "4",
        completed: false,
        title: t("sample-request-create-poultry.samples-step.step.header"),
        description: t("sample-request-create-poultry.samples-step.step.description")
      }
    ]);
    setHousingPoultryOptions(
      Object.keys(HousingPoultry).map((housing) => {
        return { value: housing, text: t(`general.housing-poultry.${housing?.toLowerCase()}`) };
      })
    );
    setProductionSystemPoultryOptions(
      Object.keys(ProductionSystemPoultry).map((productionSystem) => {
        return { value: productionSystem, text: t(`general.production-system-poultry.${productionSystem?.toLowerCase()}`) };
      })
    );
    setSexOptions(
      Object.keys(SexPoultry).map((sex) => {
        return { value: sex, text: t(`general.sex-poultry.${sex?.toLowerCase()}`) };
      })
    );
    setGeneticsOptions(
      Object.keys(GeneticsPoultry).map((genetic) => {
        return { value: genetic, text: t(`general.genetics-poultry.${genetic?.toLowerCase()}`) };
      })
    );
    setPhytaseUnitOptions(
      Object.keys(PhytaseUnit).map((phytaseUnit) => {
        return { value: phytaseUnit, text: t(`general.phytase-unit.${phytaseUnit?.toLowerCase()}`) };
      })
    );
    setd3UnitOptions(
      Object.keys(D3Unit).map((d3Unit) => {
        return { value: d3Unit, text: t(`general.d3-unit.${d3Unit?.toLowerCase()}`) };
      })
    );
    setProblemAreaOptions(
      Object.keys(ProblemArea).map((problemArea) => {
        return { value: problemArea, text: t(`general.poultry.problem-area.${problemArea?.toLowerCase()}`) };
      })
    );
    setOhUnitOptions(
      Object.keys(OHUnit).map((ohUnit) => {
        return { value: ohUnit, text: t(`general.oh-unit.${ohUnit?.toLowerCase()}`) };
      })
    );
    setYesNoUnknownOptions(
      Object.keys(YesNoUnknown).map((option) => {
        return { value: option, text: t(`general.yes-no.${option?.toLowerCase()}`) };
      })
    );
    setClinicalChallengesOptions(
      Object.keys(ClinicalChallenges).map((clinicalChallenge) => {
        return { value: clinicalChallenge, text: t(`general.clinical-challenges.${clinicalChallenge?.toLowerCase()}`) };
      })
    );
    setFlockPerformanceAtSampleOptions(
      Object.keys(FlockPerformanceAtSample).map((flockPerformanceAtSample) => {
        return { value: flockPerformanceAtSample, text: t(`general.flock-performance-at-sample.${flockPerformanceAtSample?.toLowerCase()}`) };
      })
    );
    setSubSpeciesOptions(
      Object.keys(SubSpeciesPoultry).map((subSpeciesPoultry) => {
        return { value: subSpeciesPoultry, text: t(`sample-request-create-poultry.sub-species-options.${subSpeciesPoultry?.toLowerCase()}`) };
      })
    );
    setPurposeOfAnalysisOptions(
      Object.keys(PurposeOfAnalysis).map((purposeOfAnalysisOptions) => {
        return { value: purposeOfAnalysisOptions, text: t(`general.purpose-of-analysis.options.${purposeOfAnalysisOptions?.toLowerCase()}`) };
      })
    );
    setTypeOfDietOptions(
      Object.keys(TypeOfDiet).map((typeOfDiet) => {
        return { value: typeOfDiet, text: t(`sample-request-create.type-of-diet-options.${typeOfDiet?.toLowerCase()}`) };
      })
    );
    await initFarmStep();
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setSubSpecieBroilerOption = () => {
    if (subSpeciesOptions.length > 0) {
      animalStepSetValue("speciesId", subSpeciesOptions[0].value);
    }
  };

  const handleNext = (): void => {
    if (activeStep === 1 && !isFarmStepValid) {
      farmStepTrigger();
      return;
    }
    if (activeStep === 2 && (!isFarmStepValid || !isAnimalStepValid)) {
      animalStepTrigger();
      return;
    }
    else{
      setSubSpecieBroilerOption();
    }

    if (activeStep === 3 && (!isFarmStepValid || !isAnimalStepValid || !isDietStepValid)) {
      dietStepTrigger();
      return;
    }
    if (activeStep === 4 && (!isFarmStepValid || !isAnimalStepValid || !isDietStepValid || !isSamplesStepValid)) {
      samplesStepTrigger();
      return;
    }
    
    if (activeStep === 4 && (isFarmStepValid || isAnimalStepValid || isDietStepValid || isSamplesStepValid)) {
      handleSubmit();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      await createSampleRequestPoultry(
        farmStepGetValues(),
        animalStepGetValues(),
        dietStepGetValues(),
        samplesStepGetValues(),
        testReasonProblem,
        isReproduction
      );
      setIsSubmitted(true);
    } catch (e: unknown) {
      const error = e as Error;
      setIsSubmitting(false);
      setToast({ text: t("general.error", { action: t("sample-request-create-poultry.error-action") }), error: error.message });
    }
  };

  const initFarmStep = async (): Promise<void> => {
    await initCustomers();
    setIsLoading(false);
  };

  const initCustomers = async (): Promise<void> => {
    if (role !== Role.Manager && role !== Role.Admin) {
      farmStepSetValue("customerId", customer?.id ?? "");
      return;
    }

    const customerOptions = await getCustomersForSelect(setToast);
    setCustomerOptions(customerOptions);
    if (customer?.id) {
      farmStepSetValue("customerId", customer?.id ?? "");
    }
  };

  const getFarms = async (): Promise<void> => {
    if (!customerId) {
      setFarmOptions([]);
      return;
    }

    setIsLoadingFarms(true);

    const farms = await getLocationsForCustomer(customerId || "", setToast);
    setFarms(farms);
    
    const options = farms.filter((farm) => farm.groups?.includes(SpeciesGroup.Poultry)).map((farm) => {
      return { value: farm.id || "", text: farm.name || "" };
    });

    options.sort((a, b) => a.text.localeCompare(b.text));

    setFarmOptions(options);
    setIsLoadingFarms(false);
  };

  const handleCustomerChange = async (): Promise<void> => {
    farmStepResetField("farmId");
    await getFarms();
  };

  const handleSpeciesChange = async (): Promise<void> => {
    if (speciesId) {
      const phases = await getPhase(speciesId, setToast);
      setFeedingPhaseOptions(convertPhasesForSelect(phases));
    }
  };

  const handleLocationChange = async (): Promise<void> => {
    farmStepResetField("speciesGroup");
    animalStepResetField("speciesGroup");
    const farm = farms.find((_) => _.id === farmId);
    setSelectedFarm(farm || null);
    const newSpeciesGroup = farm?.groups?.[0];
    if (newSpeciesGroup && selectedFarm?.groups?.[0] !== newSpeciesGroup) {
      farmStepSetValue("speciesGroup", newSpeciesGroup);
      animalStepSetValue("speciesGroup", newSpeciesGroup);
      setIsLoadingSpecies(true);
      setSpeciesOptions([]);
      const species = await getSpeciesForSelect(newSpeciesGroup, setToast);

      const poultrySubSpecie = species.find(element => element.text === "Broiler");
      const specie: DsmSelectOption[] = poultrySubSpecie ? [poultrySubSpecie] : [];
      setSubSpeciesOptions(specie);
      setSpeciesOptions([{text: "Poultry", value: "Poultry"}]);
      setIsLoadingSpecies(false);
    }
  };

  const handleProblemAreaChange = (): void => {
    animalStepResetField("specificProblemArea");

    if (problemAreaValue && problemAreaValue?.toLowerCase() in specificProblemArea){  
      setSpecificProblemAreaOptions(specificProblemArea[problemAreaValue?.toLowerCase() as keyof typeof specificProblemArea].map((specificProblemAreaValue) => {
        return { value: specificProblemAreaValue, text: t(`general.poultry.specific-problem-area.${specificProblemAreaValue?.toLowerCase()}`) };
      }));
    }
  };

  const handleAddCard = (code: string) => {
    appendSample({ card: Number.parseInt(code) ?? 0, problemAnswer: "" });
  };

  const handleRemoveCard = (index: number) => {
    removeSample(index);
  };

  const handleFlockPerformanceAtSampleUnitValue = () => {
    let unit = t("sample-request-create-poultry.performance-at-sample-unit");
    if (flockPerformanceAtSample === "AverageBodyWeight") {
      unit = t("sample-request-create-poultry.performance-at-sample-unit-avarage-body-weight");
    }
    else if (flockPerformanceAtSample === "AverageFcr"){
      unit = "";
    }
    return unit;
  }

  const openQrScanner = () => {
    qrScannerRef?.current?.open();
  };

  const handleScannedCodes = (codes: string[]) => {
    if (codes && codes.length > 0) {
      samplesFields.forEach((field, index) => {
        if (!field.card) {
          handleRemoveCard(index);
        }
      });
      codes.forEach((code) => {
        if (!samplesFields.find((_) => _.card.toString() === code)) {
          handleAddCard(code);
        }
      });
    }
  };

  const cardRowHasError = (index: number): boolean => {
    return !!(
      samplesStepErrors.cards?.[index]?.card ||
      samplesStepErrors.cards?.[index]?.animal ||
      samplesStepErrors.cards?.[index]?.parity ||
      samplesStepErrors.cards?.[index]?.problemAnswer
    );
  };

  return (
    <>
      <DsmGrid className={`main-container main-container--with-breadcrumb ${styles["grid"]}`} fixed={true} container-only={true}>
        <PageHeader header={t("sample-request-create-poultry.page.title")} description={t("sample-request-create-poultry.page.description")}></PageHeader>
        <DsmProgressSteps
          className={styles["progress"]}
          stacked={breakpoint === Breakpoints.xs}
          steps={steps}
          active-step={activeStep}
          onDsmStepChange={(e) => setActiveStep(Number.parseInt(e.detail))}
        ></DsmProgressSteps>
        <div className={`${styles.step} ${activeStep === 1 ? styles["step--active"] : ""}`}>
          {!isLoading ? (
            <div className={`${styles['form-poultry']}`}>
              <div className={styles['form-poultry__part']}>
                {role === Role.Manager || role === Role.Admin ? (
                  <Select
                    fieldName="customerId"
                    control={farmStepControl}
                    options={customerOptions}
                    label={t("sample-request.customer")}
                    fieldError={farmStepErrors.customerId}
                    schema={farmStepSchema}
                  ></Select>
                ) : null}
                <Select
                  fieldName="farmId"
                  control={farmStepControl}
                  options={farmOptions}
                  label={t("sample-request.farm")}
                  fieldError={farmStepErrors.farmId}
                  schema={farmStepSchema}
                  disabled={!customerId}
                  loading={isLoadingFarms}
                  emptyText={t("general.no-options")}
                ></Select>
                <Input
                  control={farmStepControl}
                  fieldName="houseNumber"
                  label={t("sample-request-create-poultry.house-number")}
                  fieldError={farmStepErrors.houseNumber}
                  schema={farmStepSchema}
                ></Input>
              </div>
              <div className={styles['form-poultry__part']}>
                {!selectedFarm?.groups?.[0] || selectedFarm?.groups?.[0] === SpeciesGroup.Poultry ? (
                  <>
                    <RadioButtonGroup
                      control={farmStepControl}
                      fieldName="housingPoultry"
                      label={t("sample-request.housing")}
                      tooltip={t("sample-request-create-poultry.tooltip-housing")}
                      options={!selectedFarm?.groups?.[0] ? [] : housingPoultryOptions}
                      noOptionsText={t("general.select-farm")}
                      fieldError={farmStepErrors.housingPoultry}
                      disabled={!selectedFarm?.groups?.[0]}
                      forceRequired={true}
                    ></RadioButtonGroup>
                  </>
                ) : null}
                {!selectedFarm?.groups?.[0] || selectedFarm?.groups?.[0] === SpeciesGroup.Poultry ? (
                  <>
                    <Select
                      control={farmStepControl}
                      fieldName="productionPoultry"
                      label={t("sample-request.production")}
                      options={!selectedFarm?.groups?.[0] ? [] : productionSystemPoultryOptions}
                      noOptionsText={t("general.select-farm")}
                      fieldError={farmStepErrors.productionPoultry}
                      disabled={!selectedFarm?.groups?.[0]}
                      forceRequired={true}
                    ></Select>
                    {productionPoultry === ProductionSystemPoultry.Other ? (
                      <Input
                        control={farmStepControl}
                        fieldName="productionPoultryOther"
                        label={t("sample-request.production-other")}
                        fieldError={farmStepErrors.productionPoultryOther}
                        forceRequired={true}
                      ></Input>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          ) : (
            <DsmLoadingIndicator className={styles["loader"]} size="md"></DsmLoadingIndicator>
          )}
        </div>
        <div className={`${styles.step} ${activeStep === 2 ? styles["step--active"] : ""}`}>
          <div className={`${styles['form-poultry']}`}>
            <div className={styles['form-poultry__part']}>
              <Select
                  fieldName="speciesGroup"
                  control={animalStepControl}
                  options={speciesOptions}
                  label={t("sample-request.species")}
                  schema={animalStepSchema}
                  loading={isLoadingSpecies}
                  disabled={true}
              ></Select>
              <Select
                  fieldName="speciesId"
                  control={animalStepControl}
                  options={subSpeciesOptions}
                  label={t("sample-request-create-poultry.sub-species")}
                  fieldError={animalStepErrors.speciesId}
                  schema={animalStepSchema}
              ></Select>
              <NumberInput
                  control={animalStepControl}
                  fieldName="age"
                  label={t("sample-request-create-poultry.age")}
                  fieldError={animalStepErrors.age}
                  schema={farmStepSchema}
              ></NumberInput>
              <Select
                  fieldName="problemArea"
                  control={animalStepControl}
                  options={problemAreaOptions}
                  label={t("sample-request.problem-area")}
                  fieldError={animalStepErrors.problemArea}
                  schema={animalStepSchema}
                  disabled={problemAreaOptions.length === 0}
              ></Select>
              {problemAreaValue?.toLowerCase() != ProblemArea.Immunity?.toLowerCase() && (
                <Select
                    fieldName="specificProblemArea"
                    control={animalStepControl}
                    options={specificProblemAreaOptions}
                    label={t("sample-request.specific-problem-area")}
                    fieldError={animalStepErrors.specificProblemArea}
                    noOptionsText={t("general.select-specific-problem")}
                    schema={animalStepSchema}
                    disabled={specificProblemAreaOptions.length === 0}
                ></Select>
              )}

              <Select
                  fieldName="clinicalChallenges"
                  control={animalStepControl}
                  options={clinicalChallengesOptions}
                  label={t("sample-request.clinical-challenges")}
                  fieldError={animalStepErrors.clinicalChallenges}
                  schema={animalStepSchema}
              ></Select>

              {clinicalChallenge?.toLowerCase() == ClinicalChallenges.Meatqualitychallenges.toLowerCase() ? (
                <RadioButtonGroup
                  control={animalStepControl}
                  fieldName="clinicalChallengesUnit"
                  label={t("sample-request.clinical-challenges-unit")}
                  options={[{value: "1", text: "Yes"}, {value: "0", text: "No"}]}
                  fieldError={animalStepErrors.clinicalChallengesUnit}
                  schema={animalStepSchema}
                ></RadioButtonGroup>
              ) : (
                <NumberInput
                  control={animalStepControl}
                  fieldName="clinicalChallengesUnit"
                  label={t("sample-request.clinical-challenges-unit")}
                  fieldError={animalStepErrors.clinicalChallengesUnit}
                  schema={animalStepSchema}
                  unit={t("sample-request-create-poultry.clinical-challenges-unit")}
                ></NumberInput>
              )}
              
            </div>
            <div className={styles['form-poultry__part']}>
              <Select
                  fieldName="flockPerformanceAtSample"
                  control={animalStepControl}
                  options={flockPerformanceAtSampleOptions}
                  label={t("sample-request.flock-performance-at-sample")}
                  fieldError={animalStepErrors.flockPerformanceAtSample}
                  schema={animalStepSchema}
              ></Select>
              <NumberInput
                  fieldName="flockPerformanceAtSampleUnit"
                  control={animalStepControl}
                  label={t("sample-request.flock-performance-at-sample-unit")}
                  fieldError={animalStepErrors.flockPerformanceAtSampleUnit}
                  schema={animalStepSchema}
                  unit={handleFlockPerformanceAtSampleUnitValue()}
              ></NumberInput>
              <RadioButtonGroup
                control={animalStepControl}
                fieldName="sexPoultry"
                label={t("sample-request.sex")}
                options={sexOptions}
                fieldError={animalStepErrors.sexPoultry}
                schema={animalStepSchema}
              ></RadioButtonGroup>
              <Select
                fieldName="geneticPoultry"
                control={animalStepControl}
                options={geneticsOptions}
                label={t("sample-request.genetics")}
                fieldError={animalStepErrors.geneticPoultry}
                emptyText={t("general.no-options")}
                forceRequired={true}
              ></Select>
              <Input
                control={animalStepControl}
                fieldName="breedAndStrain"
                label={t("sample-request-create-poultry.breed-and-strain")}
                fieldError={animalStepErrors.breedAndStrain}
                schema={animalStepSchema}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`${styles.step} ${activeStep === 3 ? styles["step--active"] : ""}`}>
          <div className={`${styles['form-poultry']}`}>
            <div className={styles['form-poultry__part']}>
              <div className={styles["form-poultry__unit-row"]}>
                <NumberInput
                  control={dietStepControl}
                  fieldName="d3Level"
                  label={t("sample-request.d3-level")}
                  tooltip={t("sample-request-create-poultry.tooltip-d3-level")}
                  fieldError={dietStepErrors.d3Level}
                  schema={dietStepFormSchema}
                ></NumberInput>
                <Select
                  fieldName="d3Unit"
                  control={dietStepControl}
                  options={d3UnitOptions}
                  fieldError={dietStepErrors.d3Unit}
                  schema={dietStepFormSchema}
                ></Select>
              </div>
              <div className={styles["form-poultry__unit-row"]}>
                <NumberInput
                  control={dietStepControl}
                  fieldName="ohLevel"
                  label={t("sample-request.oh-level")}
                  tooltip={t("sample-request-create-poultry.tooltip-oh-level")}
                  fieldError={dietStepErrors.ohLevel}
                  schema={dietStepFormSchema}
                ></NumberInput>
                <Select
                  fieldName="ohUnit"
                  control={dietStepControl}
                  options={ohUnitOptions}
                  fieldError={dietStepErrors.ohUnit}
                  schema={dietStepFormSchema}
                ></Select>
              </div>
              <DsmInput
                label={t("sample-request.oh-level-iu-kg")}
                unit={t("sample-request.oh-level-unit-iu-kg")}
                value={calculateOhLevelIUKG(ohLevel)}
                disabled={true}
              ></DsmInput>
              <DsmInput
                label={t("sample-request.total-vitamin-d3")}
                unit={t("sample-request.total-vitamin-d3-unit")}
                value={calculateTotalVitamineD3(ohLevel, d3Level)}
                disabled={true}
              ></DsmInput>
            </div>
            <div className={styles['form-poultry__part']}>
              <NumberInput
                control={dietStepControl}
                fieldName="caTotal"
                label={t("sample-request.ca-level")}
                tooltip={t("sample-request-create-poultry.tooltip-ca-level")}
                fieldError={dietStepErrors.caTotal}
                schema={dietStepFormSchema}
                unit={t("sample-request-create-poultry.ca-level-unit")}
              ></NumberInput>
              <NumberInput
                control={dietStepControl}
                fieldName="caLevel"
                label={t("sample-request.ca-available")}
                tooltip={t("sample-request-create-poultry.tooltip-ca-level")}
                fieldError={dietStepErrors.caLevel}
                schema={dietStepFormSchema}
                unit={t("sample-request-create-poultry.ca-level-unit")}
              ></NumberInput>
              <NumberInput
                control={dietStepControl}
                fieldName="pTotal"
                label={t("sample-request.p-total")}
                tooltip={t("sample-request-create-poultry.tooltip-p-total")}
                fieldError={dietStepErrors.pTotal}
                schema={dietStepFormSchema}
                unit={t("sample-request.p-total-unit")}
              ></NumberInput>
              <NumberInput
                control={dietStepControl}
                fieldName="pAvailable"
                label={t("sample-request.p-available")}
                tooltip={t("sample-request-create-poultry.tooltip-p-total")}
                fieldError={dietStepErrors.pAvailable}
                schema={dietStepFormSchema}
                unit={t("sample-request.p-available-unit")}
              ></NumberInput>
              <div className={styles["form-poultry__unit-row"]}>
                <NumberInput
                  control={dietStepControl}
                  fieldName="phytase"
                  label={t("sample-request.phytase-poultry")}
                  tooltip={t("sample-request-create-poultry.tooltip-phytase")}
                  fieldError={dietStepErrors.phytase}
                  schema={dietStepFormSchema}
                ></NumberInput>
                <Select
                  fieldName="phytaseUnit"
                  control={dietStepControl}
                  options={phytaseUnitOptions}
                  fieldError={dietStepErrors.phytaseUnit}
                  schema={dietStepFormSchema}
                ></Select>
              </div>
              <Select
                  fieldName="feedingPhase"
                  label={t("sample-request-create-poultry.feeding-phase")}
                  control={dietStepControl}
                  options={feedingPhaseOptions}
                  fieldError={dietStepErrors.feedingPhase}
                  schema={dietStepFormSchema}
                  forceRequired={true}
              ></Select>
              <Select
                  fieldName="typeOfDiet"
                  label={t("sample-request.type-of-diet")}
                  control={dietStepControl}
                  options={typeOfDietOptions}
                  fieldError={dietStepErrors.typeOfDiet}
                  schema={dietStepFormSchema}
              ></Select>
              {typeOfDiet?.toLowerCase() == TypeOfDiet.OTHER.toLowerCase() ? (
                 <Input
                 control={dietStepControl}
                 fieldName="otherTypeOfDiet"
                 label={t("sample-request.other-type-of-diet")}
                 fieldError={dietStepErrors.otherTypeOfDiet}
                 forceRequired={true}
               ></Input>
              ) : ""}
            </div>
          </div>
        </div>
        <div className={`${styles.step} ${activeStep === 4 ? styles["step--active"] : ""}`}>
          <div className={`${styles['form-poultry']}`}>
            <div className={styles['form-poultry__part']}>
              <DateInput
                control={samplesStepControl}
                fieldName="collectionDate"
                label={t("sample-request.collection-date")}
                fieldError={samplesStepErrors.collectionDate}
                schema={samplesStepFormSchema}
              ></DateInput>
              <Select
                fieldName="purposeOfAnalysis"
                label={t("general.purpose-of-analysis.label")}
                control={samplesStepControl}
                options={purposeOfAnalysisOptions}
                fieldError={samplesStepErrors.purposeOfAnalysis}
                schema={samplesStepFormSchema}
                forceRequired={true}
              ></Select>
            </div>
            <div className={styles['form-poultry__part']}>
              <TextArea
                control={samplesStepControl}
                fieldName="remarks"
                label={t("sample-request.remarks")}
                rows={4}
                fieldError={samplesStepErrors.remarks}
                schema={samplesStepFormSchema}
              ></TextArea>
            </div>
          </div>
          <div className={styles["cards"]}>
            {samplesStepErrors.cards?.message ? <div className={styles["form-error"]}>{samplesStepErrors.cards?.message}</div> : null}
            {samplesFields.map((field, index) => {
              return (
                <div key={field.id} className={styles["cards__row"]}>
                  <div className={`${styles["cards__fields"]} ${cardRowHasError(index) ? styles["cards__fields--error"] : ""}`}>
                    <NumberInput
                      control={samplesStepControl}
                      fieldName={`cards.${index}.card`}
                      fieldError={samplesStepErrors.cards?.[index]?.card}
                      label={t("sample-request.card")}
                      tooltip={t("sample-request-create-poultry.tooltip-card")}
                      forceRequired={true}
                    ></NumberInput>
                    <Input
                      control={samplesStepControl}
                      fieldName={`cards.${index}.animal`}
                      fieldError={samplesStepErrors.cards?.[index]?.animal}
                      label={t("sample-request.animal")}
                      tooltip={t("sample-request-create.tooltip-animal-poultry")}
                    ></Input>
                    {isReproduction ? (
                      <Input
                        control={samplesStepControl}
                        fieldName={`cards.${index}.parity`}
                        fieldError={samplesStepErrors.cards?.[index]?.parity}
                        label={t("sample-request.parity")}
                      ></Input>
                    ) : null}
                    {testReasonProblem ? (
                      <RadioButtonGroup
                        control={samplesStepControl}
                        fieldName={`cards.${index}.problemAnswer`}
                        options={yesNoUnknownOptions}
                        fieldError={samplesStepErrors.cards?.[index]?.problemAnswer}
                        label={t(`general.poultry.test-reason-problem.${testReasonProblem?.toLowerCase()}`)}
                        forceRequired={true}
                      ></RadioButtonGroup>
                    ) : null}
                  </div>
                  {samplesFields.length > 1 ? (
                    <DsmIcon
                      className={styles["cards__delete-icon"]}
                      name="general/trash-01"
                      size="md"
                      onClick={() => handleRemoveCard(index)}
                    ></DsmIcon>
                  ) : null}
                </div>
              );
            })}
            <div className={styles.cards__actions}>
              <DsmButton onClick={() => handleAddCard("")}>
                {t("sample-request-create-poultry.add-card")}
                <DsmIcon slot="before" name="general/plus"></DsmIcon>
              </DsmButton>
              <DsmButton onClick={openQrScanner}>
                {t("sample-request-create-poultry.scan-cards")}
                <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
              </DsmButton>
            </div>
          </div>
        </div>
        <div className={styles["progress-controls"]}>
          <div>
            {activeStep > 1 ? (
              <DsmButton variant="primary" disabled={activeStep === 1} onClick={handleBack}>
                <DsmIcon name="arrows/arrow-left"></DsmIcon>
                {t("general.back")}
              </DsmButton>
            ) : null}
          </div>
          <DsmButton variant="primary" disabled={isSubmitting} onClick={handleNext}>
            <DsmIcon name={activeStep === 4 ? "general/save-01" : "arrows/arrow-right"}></DsmIcon>
            {activeStep === 4 ? t("sample-request-create-poultry.submit") : t("general.next")}
          </DsmButton>
        </div>
      </DsmGrid>
      <QrModal saveFunc={handleScannedCodes} ref={qrScannerRef}></QrModal>
      <ConfirmModal show={isSubmitted}></ConfirmModal>
    </>
  );
}

export default SampleRequestPoultryCreate;
