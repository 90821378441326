import { DsmColors } from "@dsm-dcs/design-system";
import { DsmTableData } from "@dsm-dcs/design-system/dist/types/types/table";
import dayjs from "dayjs";
import i18next from "i18next";
import { Phase, SampleResultCalculations, SampleResultTableItem } from "../models/API";
import { SampleListTableActions } from "../models/enums/actions.enum";
import { IResultList } from "../models/resultList.model";

export const handleSamplesTableData = (
  data: SampleResultTableItem[],
  sampleCalculations: SampleResultCalculations | null,
  addLocation = false
): DsmTableData => {
  const tableData = {
    columns: [
      { id: "collectionDate", label: i18next.t("sample-request.collection-date") },
      { id: "name", label: i18next.t("sample-request.name") },
      { id: "phase", label: i18next.t("sample-request.phase") },
      { id: "average", label: i18next.t("sample-request.average") }
    ],
    data: data.map((_) => {
      return {
        isClickable: true,
        id: _.sampleRequestId || "",
        actions: [
          {
            type: SampleListTableActions.Results,
            icon: "charts/bar-chart-square-01",
            title: i18next.t("farm-sample-request-list.table-actions.results")
          },
          {
            type: SampleListTableActions.Delete,
            icon: "general/trash-01",
            title: i18next.t("general.delete"),
            destructive: true,
            disabled: true
          }
        ],
        cells: [
          { value: dayjs(_.collectionDate).format(i18next.t("general.date-format")) },
          { headerText: _.name || "" },
          { value: _.phaseName || "" },
          {
            badges: [
              {
                value: _.average && _.average > 0 ? `${_.average?.toString()} ng/mL` : i18next.t("general.result-status.pending"),
                variant: getAverageVariant(sampleCalculations?.selectedPhase, _.average)
              }
            ]
          }
        ]
      };
    })
  };
  if (addLocation) {
    tableData.columns.splice(2, 0, { id: "location", label: i18next.t("sample-request.farm") });
    data.forEach((_, i) => {
      tableData.data[i].cells.splice(2, 0, { value: _.locationName || "" });
    });
  }
  return tableData;
};

export const getAverageText = (phase: Phase | null | undefined, average: number | null | undefined): string => {
  if (!phase || average === null || average === undefined) {
    return "";
  } else {
    if (average < (phase.deficient || 0)) {
      return "Deficient";
    } else if (average < (phase.insufficient || 0)) {
      return "Insufficient";
    } else if (average < (phase.adequate || 0)) {
      return "Suboptimum";
    } else {
      return "Optimum";
    }
  }
};

export const getAverageVariant = (phase: Phase | null | undefined, average: number | null | undefined): DsmColors => {
  if (!phase || average === null || average === undefined) {
    return "primary";
  } else {
    if (average < (phase.deficient || 0)) {
      return "error";
    } else if (average < (phase.insufficient || 0)) {
      return "warning";
    } else if (average < (phase.adequate || 0)) {
      return "ocean-green";
    } else {
      return "dark-green";
    }
  }
};

export const handleResultListData = (sampleCalculations: SampleResultCalculations): IResultList => {
  return {
    valueHeader: sampleCalculations?.selectedPhase
      ? i18next.t("general.result-description.value-unit")
      : i18next.t("general.result-description.value-level"),
    descriptionHeader: i18next.t("general.result-description.description"),
    data: [
      {
        type: "optimum",
        value: sampleCalculations.optimum ?? 0,
        range: sampleCalculations?.selectedPhase
          ? `>${sampleCalculations?.selectedPhase?.adequate ?? sampleCalculations?.selectedPhase?.insufficient} ${i18next.t(
              "general.unit-25ohd3"
            )}`
          : null,
        header: sampleCalculations?.selectedPhase
          ? `${i18next.t("general.result-breakdown.optimum")}: >${
              sampleCalculations?.selectedPhase?.adequate ?? sampleCalculations?.selectedPhase?.insufficient
            } ${i18next.t("general.unit-25ohd3")}`
          : i18next.t("general.result-breakdown.optimum"),
        description: i18next.t("general.result-description.optimum")
      },
      {
        type: "adequate",
        value: sampleCalculations.adequate ?? 0,
        range: sampleCalculations?.selectedPhase
          ? `${sampleCalculations?.selectedPhase?.insufficient}-${sampleCalculations?.selectedPhase?.adequate} ${i18next.t(
              "general.unit-25ohd3"
            )}`
          : null,
        header: sampleCalculations?.selectedPhase
          ? `${i18next.t("general.result-breakdown.adequate")}: ${sampleCalculations?.selectedPhase?.insufficient}-${sampleCalculations
              ?.selectedPhase?.adequate} ${i18next.t("general.unit-25ohd3")}`
          : i18next.t("general.result-breakdown.adequate"),
        description: i18next.t("general.result-description.adequate")
      },
      {
        type: "insufficient",
        value: sampleCalculations.insufficient ?? 0,
        range: sampleCalculations?.selectedPhase
          ? `${sampleCalculations?.selectedPhase?.deficient}-${sampleCalculations?.selectedPhase?.insufficient} ${i18next.t(
              "general.unit-25ohd3"
            )}`
          : null,
        header: sampleCalculations?.selectedPhase
          ? `${i18next.t("general.result-breakdown.insufficient")}: ${sampleCalculations?.selectedPhase?.deficient}-${sampleCalculations
              ?.selectedPhase?.insufficient} ${i18next.t("general.unit-of-25ohd3")}`
          : i18next.t("general.result-breakdown.insufficient"),
        description: i18next.t("general.result-description.insufficient")
      },
      {
        type: "deficient",
        value: sampleCalculations.deficient ?? 0,
        range: sampleCalculations?.selectedPhase
          ? `<${sampleCalculations?.selectedPhase?.deficient} ${i18next.t("general.unit-25ohd3")}`
          : null,
        header: sampleCalculations?.selectedPhase
          ? `${i18next.t("general.result-breakdown.deficient")}: <${sampleCalculations?.selectedPhase?.deficient} ${i18next.t(
              "general.unit-of-25ohd3"
            )}`
          : i18next.t("general.result-breakdown.deficient"),
        description: i18next.t("general.result-description.deficient")
      }
    ]
  };
};

export const calculateOhLevelIUKG = (ohLevel: number): string => {
  return ohLevel ? (ohLevel * 40).toString() : "";
};

export const calculateTotalVitamineD3 = (ohLevel: number, d3Level: number): string => {
  return ohLevel || d3Level ? (ohLevel * 40 + d3Level).toString() : "";
};

export const getScatterCss = (phase: Phase | null | undefined, resultValues: number[], leftMargin: string): string => {
  if (!phase) {
    return "";
  }

  const max = Math.max(...resultValues.concat([phase?.optimum || 0]));
  const deficient = phase.deficient ? 100 - (100 / max) * phase.deficient : 0;
  const insufficient = phase.insufficient ? 100 - (100 / max) * phase.insufficient : 0;
  const adequate = phase.adequate ? 100 - (100 / max) * phase.adequate : insufficient;

  return `.scatter { &::before { width: calc(100% - ${leftMargin}px); margin-left: ${leftMargin}px; background: linear-gradient(to bottom, rgba(118, 208, 194, 0.32) 0%, rgba(118, 208, 194, 0.32) ${adequate}%, rgba(118, 208, 194, 0.12) ${adequate}%, rgba(118, 208, 194, 0.12) ${insufficient}%, rgba(255, 238, 219, 1) ${insufficient}%, rgba(255, 238, 219, 1) ${deficient}%, rgba(230, 127, 124, 0.2) ${deficient}%, rgba(230, 127, 124, 0.2) 100%); } }`;
};

export const getBoxPlotBackCss = (): string => {
  const max = 100;
  const deficient = 100 - (100 / max) * 20;
  const insufficient = 100 - (100 / max) * 30;
  const adequate = 100 - (100 / max) * 60;

  return `.box-plot { &::before { background: linear-gradient(to bottom, #76d0c2 0%, #76d0c2 ${adequate}%, #c3e6e1 ${adequate}%, #c3e6e1 ${insufficient}%, #ffaa4d ${insufficient}%, #ffaa4d ${deficient}%, #e67f7c ${deficient}%, #e67f7c 100%); } }`;
};
