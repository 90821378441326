import { API, GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { Dispatch, SetStateAction } from "react";
import { BaseQuery } from "../graphql/interfaces";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import {
  AddLocationForCustomerMutation,
  AddLocationForCustomerMutationVariables,
  BaseLocation,
  GetLocationQuery,
  GetLocationQueryVariables,
  GetLocationsForCustomerQuery,
  GetLocationsForCustomerQueryVariables,
  GetLocationsQuery,
  GetLocationsQueryVariables,
  Location,
  LocationInput,
  LocationsFilterInput,
  LocationsTableResponse
} from "../models/API";
import { AddFarmForm } from "../models/forms/add-farm-form.model";
import { IToast } from "../models/toast.model";

export const getLocation = async (locationId: string, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Location | null> => {
  try {
    const request: BaseQuery<GetLocationQueryVariables> = {
      query: queries.getLocation,
      variables: {
        locationId
      }
    };

    const result = await API.graphql<GraphQLQuery<GetLocationQuery>>(request);
    return result.data?.getLocation?.location || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading location", error: errors[0].message });
    return null;
  }
};

export const getLocationsForCustomer = async (
  customerId: string,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<BaseLocation[]> => {
  try {
    const request: BaseQuery<GetLocationsForCustomerQueryVariables> = {
      query: queries.getLocationsForCustomer,
      variables: {
        customerId
      }
    };
    const result = await API.graphql<GraphQLQuery<GetLocationsForCustomerQuery>>(request);
    return result.data?.getLocationsForCustomer?.locations || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading locations for customer", error: errors[0].message });
    return [];
  }
};

export const getLocations = async (
  customerId: string,
  filters: LocationsFilterInput,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<LocationsTableResponse> => {
  try {
    const request: BaseQuery<GetLocationsQueryVariables> = {
      query: queries.getLocations,
      variables: {
        customerId,
        filters
      }
    };
    const result = await API.graphql<GraphQLQuery<GetLocationsQuery>>(request);
    return result.data?.getLocations || {
      locations: [],
      totalPages: 0,
      totalItems: 0
    };
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading locations", error: errors[0].message });
    return {
      locations: [],
      totalPages: 0,
      totalItems: 0
    };
  }
};

export const createLocation = async (formData: AddFarmForm): Promise<string | null> => {
  try {
    const location: LocationInput = {
      customerId: formData.customerId,
      name: formData.name,
      groups: [formData.species],
      contactName: formData.contactPerson,
      phone: formData.phone,
      contactRelation: formData.contactType,
      address: {
        street: formData.street,
        number: formData.number,
        postalCode: formData.postalCode,
        city: formData.city,
        state: formData.state,
        countryId: formData.country
      }
    };
    const request: BaseQuery<AddLocationForCustomerMutationVariables> = {
      query: mutations.addLocationForCustomer,
      variables: { input: location }
    };
    const result = await API.graphql<GraphQLQuery<AddLocationForCustomerMutation>>(request);

    return result.data?.addLocationForCustomer?.location?.id || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    throw new Error(errors[0].message);
  }
};
