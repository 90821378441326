import { useTranslation } from "react-i18next";
import { StepProps } from "../../../../types";
import styles from "./Step.module.scss";

const Step = ({ icon: Icon, titleKey, descriptionKey }: StepProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles["user-guider__step"]}>
      <Icon width="88" height="88" />
      <span className={styles["user-guider__step-title"]}>
        {t(titleKey)}
      </span>
      <p className={styles["user-guider__step-description"]}>
        {t(descriptionKey)}
      </p>
    </div>
  );
};

export default Step;