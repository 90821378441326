export enum HousingSwine {
  Indoor = "Indoor",
  Outdoor = "Outdoor",
  Mixed = "Mixed"
}

export enum HousingPoultry {
  Controlled = "Controlled",
  Open = "Open",
  Outdoor = "Outdoor",
  Mixed = "Mixed"
}
