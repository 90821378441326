import { useTranslation } from "react-i18next";
import { DsmProgressCircle, DsmAlert, DsmStepper, DsmLoadingIndicator } from "@dsm-dcs/design-system-react";
import { Dispatch, SetStateAction, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import styles from "./orderStep.module.scss";
import { getCustomerForUser } from "../../../../../services/user.service";
import { BaseCustomer } from "../../../../../models/API";
import kitImage from "../../../../../assets/images/kit.jpg";
import { useLayout } from "../../../../../contexts/layout.context";
import { Role } from "../../../../../models/enums/role.enum";
import { AuthContext } from "../../../../../contexts/auth.context";
import { Auth } from "aws-amplify";

export type OrderStepRef = {
  getData: () => number;
};

type Props = {
  setFormStepValid: Dispatch<SetStateAction<boolean>>;
};

const OrderStep = forwardRef<OrderStepRef, Props>(function OrderStep({ setFormStepValid }, ref) {
  //Hooks
  const { t } = useTranslation();
  const { setToast } = useLayout();
  const { role } = useContext(AuthContext);

  //State
  const price = 1;
  const maxBudget = 10000;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [amountOfKits, setAmountOfKits] = useState<number>(1);
  const [customer, setCustomer] = useState<BaseCustomer | null>(null);
  const [budget, setBudget] = useState<number>(0);
  const [currentPrice, setCurrentPrice] = useState<number>(price);
  const [budgetPercentage, setBudgetPercentage] = useState<number>(0);

  //Exposed methods
  useImperativeHandle(
    ref,
    () => {
      return {
        getData: (): number => {
          return amountOfKits;
        }
      };
    },
    [amountOfKits]
  );

  //Effects
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    handleAmountOfKitsChange();
  }, [amountOfKits, budget]);

  //Methods
  const initData = async () => {
    const customer = await getCustomerForUser(setToast);
    setCustomer(customer);
    if (customer?.currentBudget) {
      setBudget(customer.currentBudget);
    }
    setIsLoading(false);
  };

  const handleAmountOfKitsChange = () => {
    if (role === Role.Customer) {
      return;
    }

    setFormStepValid(amountOfKits > 0);

    // if (!customer?.currentBudget) {
    //   return;
    // }

    // const newPrice = price * amountOfKits;
    // const newBudget = customer.currentBudget - newPrice;
    // setFormStepValid(amountOfKits > 0 && (role === Role.Manager || newBudget >= 0));
    // setBudget(newBudget);
    // setCurrentPrice(newPrice);
    // setBudgetPercentage(Math.ceil((newBudget / maxBudget) * 100));
  };

  return (
    <>
      {!isLoading ? (
        <>
          {role !== Role.Admin && budget < 0 ? (
            <DsmAlert
              data-testid="alert-budget"
              className={styles.alert}
              closeable={false}
              variant="error"
              header={t("order-new-kit.order-step.budget-alert.header")}
            >
              {t("order-new-kit.order-step.budget-alert.description")}
              <b slot="link">{t("order-new-kit.order-step.budget-alert.sub-text")}</b>
            </DsmAlert>
          ) : null}

          <div className={styles.cards}>
            <div className={`card ${styles["kits-card"]}`}>
              <img src={kitImage} alt=""></img>
              <div>
                <b>{t("order-new-kit.order-step.kits-card.header")}</b>
                <div>{t("order-new-kit.order-step.kits-card.description")}</div>
                <div className={styles["stepper-container"]}>
                  <div>
                    {/* <div className={styles["card-sub-header"]}>{t("order-new-kit.order-step.kits-card.sub-header")}</div>
                    <div className={styles.currency}>€{currentPrice}</div> */}
                  </div>
                  <DsmStepper
                    data-testid="stepper-amountOfKits"
                    label=""
                    value={amountOfKits}
                    onDsmChange={(e) => {
                      setAmountOfKits(Number(e.detail));
                    }}
                  ></DsmStepper>
                </div>
              </div>
            </div>
            {/* <div className={`card ${styles["budget-card"]}`}>
              <DsmProgressCircle
                data-testid="progress-budget"
                size="sm"
                variant="circle"
                percentage={budgetPercentage}
                description=""
              ></DsmProgressCircle>
              <div>
                <div>
                  <b>{t("order-new-kit.order-step.budget-card.header")}</b>
                  <div>{t("order-new-kit.order-step.budget-card.description")}</div>
                </div>
                <div className={styles["card-sub-header"]}>
                  <b>{t("order-new-kit.order-step.budget-card.sub-header")}</b>
                </div>
                <div className={styles.currency}>€{budget}</div>
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <DsmLoadingIndicator className={styles["loader"]} size="md"></DsmLoadingIndicator>
      )}
    </>
  );
});

export default OrderStep;
