import i18next from "i18next";
import * as yup from "yup";
import { SpeciesGroup } from "../API";
import { Sex } from "../enums/sex.enum";

export interface AnimalStepForm {
  speciesGroup: SpeciesGroup;
  speciesId: string;
  phaseId: string;
  hasSpecificPhase?: boolean;
  specificPhase?: string | null;
  problemArea?: string | null;
  specificProblemArea?: string | null;
  sex: Sex;
  genetics?: string;
  geneticSupplier?: string | null;
  geneticSupplierOther?: string;
  geneticLineage?: string | null;
  geneticLineageOther?: string;
}

export const getAnimalStepFormSchema = () => {
  return yup.object({
    speciesGroup: yup.mixed<SpeciesGroup>().oneOf(Object.values(SpeciesGroup)).required(),
    speciesId: yup.string().required().label(i18next.t("sample-request.species")),
    phaseId: yup.string().required().label(i18next.t("sample-request.phase")),
    specificPhase: yup
      .string()
      .label(i18next.t("sample-request.specific-phase"))
      .when("hasSpecificPhase", {
        is: (hasSpecificPhase: boolean) => !!hasSpecificPhase,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired()
      }),
    hasSpecificPhase: yup.boolean(),
    problemArea: yup.string().required().label(i18next.t("sample-request.problem-area")),
    specificProblemArea: yup.string().required().label(i18next.t("sample-request.specific-problem-area")),
    sex: yup.mixed<Sex>().oneOf(Object.values(Sex)).required().label(i18next.t("sample-request.sex")),
    geneticSupplier: yup
      .string()
      .label(i18next.t("sample-request.genetic-supplier"))
      .when("speciesGroup", {
        is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Swine,
        then: (schema) => schema.required()
      }),
    geneticSupplierOther: yup
      .string()
      .label(i18next.t("sample-request.genetic-supplier-other"))
      .when("geneticSupplier", {
        is: (supplier: string) => supplier === "Other",
        then: (schema) => schema.required()
      }),
    geneticLineage: yup
      .string()
      .label(i18next.t("sample-request.genetic-lineage"))
      .when("speciesGroup", {
        is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Swine,
        then: (schema) => schema.required()
      }),
    geneticLineageOther: yup
      .string()
      .label(i18next.t("sample-request.genetic-lineage-other"))
      .when("geneticLineage", {
        is: (lineage: string) => lineage === "Other",
        then: (schema) => schema.required()
      })
  });
};
