/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type KitRequestInput = {
  address?: AddressInput | null,
  amount?: number | null,
  contactName?: string | null,
  locationId: string,
  sentToLocation?: boolean | null,
  purposeOfAnalysis?: string;
};

export type AddressInput = {
  city: string,
  countryId: string,
  number: string,
  postalCode: string,
  state: string,
  street: string,
};

export type KitRequestResponse = {
  __typename: "KitRequestResponse",
  kitRequest?: KitRequest | null,
};

export type KitRequest = {
  __typename: "KitRequest",
  address?: Address | null,
  contactName?: string | null,
  id?: string | null,
  location?: BaseLocation | null,
  orders?:  Array<KitOrder > | null,
  sentToLocation?: boolean | null,
};

export type Address = {
  __typename: "Address",
  city?: string | null,
  country?: Country | null,
  number?: string | null,
  postalCode?: string | null,
  state?: string | null,
  street?: string | null,
};

export type Country = {
  __typename: "Country",
  abbreviation?: string | null,
  id?: string | null,
  name?: string | null,
};

export type BaseLocation = {
  __typename: "BaseLocation",
  address?: Address | null,
  avgNrOfAnimals?: number | null,
  contact?: Contact | null,
  groups?: Array< SpeciesGroup > | null,
  id?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  name?: string | null,
  relation?: string | null,
};

export enum SpeciesGroup {
  Poultry = "Poultry",
  Swine = "Swine",
}


export type KitOrder = {
  __typename: "KitOrder",
  amount?: number | null,
  createdDate?: string | null,
  id?: string | null,
  kit?: Kit | null,
  status: KitOrderStatus,
  trackAndTrace?: string | null,
  transportProvider?: string | null,
};

export type Kit = {
  __typename: "Kit",
  currency?: string | null,
  id?: string | null,
  name?: string | null,
  priceEx?: number | null,
  vatPercentage?: number | null,
};

export enum KitOrderStatus {
  Cancelled = "Cancelled",
  Error = "Error",
  Pending = "Pending",
  Received = "Received",
  Shipped = "Shipped",
}


export type LocationInput = {
  address: AddressInput,
  avgNrOfAnimals?: number | null,
  contactName: string,
  contactRelation: string,
  customerId: string,
  groups: Array< SpeciesGroup >,
  name: string,
  phone?: string | null,
};

export type LocationResponse = {
  __typename: "LocationResponse",
  location?: Location | null,
};

export type Location = {
  __typename: "Location",
  address?: Address | null,
  avgNrOfAnimals?: number | null,
  contact?: Contact | null,
  customer?: Customer | null,
  groups?: Array< SpeciesGroup > | null,
  id?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type Customer = {
  __typename: "Customer",
  currency?: string | null,
  currentBudget?: number | null,
  dsmUnit?: DsmUnit | null,
  id?: string | null,
  initialBudget?: number | null,
  locations?:  Array<Location > | null,
  name?: string | null,
};

export type DsmUnit = {
  __typename: "DsmUnit",
  id?: string | null,
  name?: string | null,
};

export type ProductCompetitorInput = {
  customerId: string,
  name: string,
};

export type ProductCompetitorResponse = {
  __typename: "ProductCompetitorResponse",
  competitor?: ProductCompetitor | null,
};

export type ProductCompetitor = {
  __typename: "ProductCompetitor",
  customer?: Customer | null,
  id?: string | null,
  name?: string | null,
  productType?: ProductType | null,
  sampleType?: SampleType | null,
};

export type ProductType = {
  __typename: "ProductType",
  id?: string | null,
  name?: string | null,
};

export type SampleType = {
  __typename: "SampleType",
  id?: string | null,
  name?: string | null,
  productType?: ProductType | null,
};

export type SampleRequestInput = {
  specieId?: string | null,
  ageDays?: number | null,
  barn?: string | null,
  caLevel?: number | null,
  cards: Array< CardInput >,
  collectionDate: string,
  comment?: string | null,
  d3Level?: number | null,
  geneticLineage?: string | null,
  geneticSupplier?: string | null,
  housingSystem: string,
  locationId: string,
  ohLevel?: number | null,
  pAvailable?: number | null,
  pLevel?: number | null,
  phaseId: string | null,
  phytase?: number | null,
  phytaseUnit?: string | null,
  problem?: string | null,
  problemArea?: string | null,
  productionSystem?: string | null,
  remarks?: string | null,
  purposeOfAnalysis: string,
  sex?: string | null,
  sowStage?: string | null,
  specificPhase?: string | null,
  specificProblemArea?: string | null,
  testReason: string,
  testReasonProblem?: string | null,
};

export type SamplePoultryRequestInput = Partial<SampleRequestInput> & {
  breedAndStrain: string | null,
  clinicalChallenges?: string | null,
  clinicalChallengesUnit?: string | null,
  d3Unit?: string | null,
  caTotal?: number | null,
  pTotal?: number | null,
  flockPerformanceAtSample?: string | null,
  flockPerformanceAtSampleUnit?: string | null,
  genetic: string | null,
  ohUnit?: string | null,
  typeOfDiet?: string | null,
};

export type CardInput = {
  animalId?: string | null,
  cardId: string,
  parity?: string | null,
  problemAnswer?: boolean | null,
};

export type SampleRequestResponse = {
  __typename: "SampleRequestResponse",
  sampleRequest?: SampleRequest | null,
};

export type SampleRequest = {
  __typename: "SampleRequest",
  average?: number | null,
  barn?: string | null,
  collectionDate?: string | null,
  comment?: string | null,
  d3Level?: number | null,
  housingSystem?: string | null,
  id?: string | null,
  location?: Location | null,
  name?: string | null,
  phase?: Phase | null,
  phaseName?: string | null,
  problem?: string | null,
  problemArea?: string | null,
  productionSystem?: string | null,
  remarks?: string | null,
  samples?:  Array<Sample > | null,
  specificPhase?: string | null,
  specificProblemArea?: string | null,
  takenBy?: string | null,
  testReason?: string | null,
  testReasonProblem?: string | null,
};

export type Phase = {
  __typename: "Phase",
  adequate?: number | null,
  deficient?: number | null,
  geneticLineage?:  Array<Genetic | null > | null,
  geneticSupplier?:  Array<Genetic | null > | null,
  id?: string | null,
  insufficient?: number | null,
  name?: string | null,
  optimum?: number | null,
  testReasonConfig?: string | null,
};

export type Genetic = {
  __typename: "Genetic",
  id?: string | null,
  name?: string | null,
};

export type Sample = {
  __typename: "Sample",
  age?: number | null,
  analytes?:  Array<Analyte > | null,
  animalId?: string | null,
  cardId?: string | null,
  collectionDate?: string | null,
  comment?: string | null,
  costCenter?: CostCenter | null,
  dosage?: number | null,
  feedComposition?: FeedComposition | null,
  geneticLineage?: string | null,
  geneticSupplier?: string | null,
  id?: string | null,
  parity?: string | null,
  phase?: Phase | null,
  problemAnswer?: boolean | null,
  productCategory?: ProductCategory | null,
  productType?: ProductType | null,
  results?:  Array<SampleResults > | null,
  sex?: string | null,
  sowStage?: string | null,
  species?: Species | null,
  timeline?:  Array<SampleTimeline > | null,
};

export type Analyte = {
  __typename: "Analyte",
  id?: string | null,
  name?: string | null,
};

export type CostCenter = {
  __typename: "CostCenter",
  id?: string | null,
  name?: string | null,
};

export type FeedComposition = {
  __typename: "FeedComposition",
  caLevel?: number | null,
  pAvailable?: number | null,
  pLevel?: number | null,
  phytase?: number | null,
  phytaseUnit?: string | null,
};

export type ProductCategory = {
  __typename: "ProductCategory",
  dsmUnit?: DsmUnit | null,
  id?: string | null,
  name?: string | null,
};

export type SampleResults = {
  __typename: "SampleResults",
  analysis?: string | null,
  analysisUnit?: string | null,
  evaluation?: string | null,
  expected?: number | null,
  id?: string | null,
  line?: number | null,
  module?: string | null,
  outlier?: boolean | null,
  repetitions?: number | null,
  result?: number | null,
  unit?: string | null,
};

export type Species = {
  __typename: "Species",
  enabled?: boolean | null,
  group?: SpeciesGroup | null,
  id?: string | null,
  name?: string | null,
  phases?:  Array<Phase > | null,
};

export type SampleTimeline = {
  __typename: "SampleTimeline",
  comments?: string | null,
  date?: string | null,
  description?: string | null,
  id?: string | null,
  order?: number | null,
  status?: string | null,
};

export type SampleRequestEditInput = {
  caLevel?: number | null,
  d3Level?: number | null,
  geneticLineage?: string | null,
  geneticSupplier?: string | null,
  ohLevel?: number | null,
  pAvailable?: number | null,
  pLevel?: number | null,
  phytase?: number | null,
  phytaseUnit?: string | null,
  sampleRequestId?: string | null,
};

export type NotificationInput = {
  customerId: string,
  id?: string | null,
};

export type NotificationResponse = {
  __typename: "NotificationResponse",
  notification?: Notification | null,
};

export type Notification = {
  __typename: "Notification",
  createdAt?: string | null,
  customer?: Customer | null,
  description?: string | null,
  id?: string | null,
  messageType?: string | null,
  readBy?: string | null,
  referenceId?: string | null,
  source?: Source | null,
  status?: string | null,
  transportProvider?: string | null,
};

export enum Source {
  Nutrilab = "Nutrilab",
  ServiceCatalogue = "ServiceCatalogue",
}


export type ScanResult = {
  __typename: "ScanResult",
  isAvailable: boolean,
};

export type CountriesResponse = {
  __typename: "CountriesResponse",
  countries?:  Array<Country > | null,
};

export type CustomersResponse = {
  __typename: "CustomersResponse",
  customers?:  Array<BaseCustomer > | null,
};

export type BaseCustomer = {
  __typename: "BaseCustomer",
  currency?: string | null,
  currentBudget?: number | null,
  dsmUnitId?: string | null,
  id?: string | null,
  name?: string | null,
  numberOfFarms?: number | null,
  numberOfKits?: number | null,
  numberOfRequests?: number | null,
};

export type KitRequestTableInput = {
  itemsPerPage?: number | null,
  pageToken?: string | null,
  sortBy?: SampleResultTableSortProperties | null,
  sortDirection?: SortDirection | null,
};

export enum SampleResultTableSortProperties {
  average = "average",
  collectionDate = "collectionDate",
}


export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc",
}


export type KitRequestTable = {
  __typename: "KitRequestTable",
  total?: number,
  itemsPerPage: number,
  pages?:  Array<Page > | null,
  rows?:  Array<KitRequestTableItem > | null,
  sortBy: KitRequestTableSortProperties,
  sortDirection: SortDirection,
};

export type Page = {
  __typename: "Page",
  page?: number | null,
  token?: string | null,
};

export type KitRequestTableItem = {
  __typename: "KitRequestTableItem",
  createdDateTime?: string | null,
  customer?: Customer | null,
  kitRequestId?: string | null,
  locationName?: string | null,
  orderNumber?: number | null,
  status?: KitOrderStatus | null,
  totalAmount?: number | null,
  trackAndTrace?: string | null,
};

export enum KitRequestTableSortProperties {
  createdDateTime = "createdDateTime",
}


export type LocationsResponse = {
  __typename: "LocationsResponse",
  locations?:  Array<BaseLocation > | null,
};

export type LocationsTableResponse = {
  __typename?: 'LocationsTableResponse';
  locations?: Array<BaseLocation>;
  totalItems?: number;
  totalPages?: number;
}

export type NotificationsResponse = {
  __typename: "NotificationsResponse",
  notifications?:  Array<Notification > | null,
};

export type PhasesResponse = {
  __typename: "PhasesResponse",
  phases?:  Array<Phase > | null,
};

export type ProductCompetitorsResponse = {
  __typename: "ProductCompetitorsResponse",
  competitors?:  Array<ProductCompetitor > | null,
};

export type SampleResultCalculations = {
  __typename: "SampleResultCalculations",
  adequate?: number | null,
  availablePhases?:  Array<BasePhase > | null,
  deficient?: number | null,
  insufficient?: number | null,
  optimum?: number | null,
  selectedPhase?: Phase | null,
  totalAverage?: number | null,
};

export type BasePhase = {
  __typename: "BasePhase",
  id?: string | null,
  name?: string | null,
};

export type SampleResultTableInput = {
  excludePendingResults?: boolean | null,
  filters?: FiltersInput | null,
  itemsPerPage?: number | null,
  pageToken?: string | null,
  phaseId?: string | null,
  sortBy?: SampleResultTableSortProperties | null,
  sortDirection?: SortDirection | null,
};

export type FiltersInput = {
  average?: FloatFilter | null,
  collectionDate?: DateFilter | null,
  phaseIds?: Array< string | null > | null,
  query?: string | null,
  speciesId?: string | null,
};

export type FloatFilter = {
  type: ComparisonType,
  value: number,
};

export enum ComparisonType {
  EQ = "EQ",
  GT = "GT",
  LT = "LT",
}


export type DateFilter = {
  endDate?: string | null,
  startDate?: string | null,
};

export type SampleResultTable = {
  __typename: "SampleResultTable",
  itemsPerPage: number,
  pages?:  Array<Page > | null,
  rows?:  Array<SampleResultTableItem > | null,
  sortBy: SampleResultTableSortProperties,
  sortDirection: SortDirection,
  total?: number | null
};

export type SampleResultTableItem = {
  __typename: "SampleResultTableItem",
  average?: number | null,
  collectionDate?: string | null,
  customer?: Customer | null,
  description?: string | null,
  locationId?: string | null,
  locationName?: string | null,
  name?: string | null,
  phaseName?: string | null,
  sampleRequestId?: string | null,
  status?: string | null,
};

export type SpeciesResponse = {
  __typename: "SpeciesResponse",
  species?:  Array<BaseSpecies > | null,
};

export type BaseSpecies = {
  __typename: "BaseSpecies",
  enabled?: boolean | null,
  group?: SpeciesGroup | null,
  id?: string | null,
  name?: string | null,
};

export type AddKitRequestMutationVariables = {
  input: KitRequestInput,
};

export type AddKitRequestMutation = {
  addKitRequest:  {
    __typename: "KitRequestResponse",
    kitRequest?:  {
      __typename: "KitRequest",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      contactName?: string | null,
      id?: string | null,
      location?:  {
        __typename: "BaseLocation",
        address?:  {
          __typename: "Address",
          city?: string | null,
          number?: string | null,
          postalCode?: string | null,
          state?: string | null,
          street?: string | null,
        } | null,
        avgNrOfAnimals?: number | null,
        contact?:  {
          __typename: "Contact",
          id: string,
          name?: string | null,
          relation?: string | null,
        } | null,
        groups?: Array< SpeciesGroup > | null,
        id?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      orders?:  Array< {
        __typename: "KitOrder",
        amount?: number | null,
        createdDate?: string | null,
        id?: string | null,
        kit?:  {
          __typename: "Kit",
          currency?: string | null,
          id?: string | null,
          name?: string | null,
          priceEx?: number | null,
          vatPercentage?: number | null,
        } | null,
        status: KitOrderStatus,
        trackAndTrace?: string | null,
        transportProvider?: string | null,
      } > | null,
      sentToLocation?: boolean | null,
    } | null,
  },
};

export type AddLocationForCustomerMutationVariables = {
  input: LocationInput,
};

export type AddLocationForCustomerMutation = {
  addLocationForCustomer:  {
    __typename: "LocationResponse",
    location?:  {
      __typename: "Location",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      avgNrOfAnimals?: number | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        relation?: string | null,
      } | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      groups?: Array< SpeciesGroup > | null,
      id?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
  },
};

export type AddProductCompetitorMutationVariables = {
  input: ProductCompetitorInput,
};

export type AddProductCompetitorMutation = {
  addProductCompetitor:  {
    __typename: "ProductCompetitorResponse",
    competitor?:  {
      __typename: "ProductCompetitor",
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      id?: string | null,
      name?: string | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
      } | null,
      sampleType?:  {
        __typename: "SampleType",
        id?: string | null,
        name?: string | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
        } | null,
      } | null,
    } | null,
  },
};

export type AddSampleRequestMutationVariables = {
  input: SampleRequestInput,
};

export type AddSampleRequestMutation = {
  addSampleRequest:  {
    __typename: "SampleRequestResponse",
    sampleRequest?:  {
      __typename: "SampleRequest",
      average?: number | null,
      barn?: string | null,
      collectionDate?: string | null,
      comment?: string | null,
      d3Level?: number | null,
      housingSystem?: string | null,
      id?: string | null,
      location?:  {
        __typename: "Location",
        address?:  {
          __typename: "Address",
          city?: string | null,
          number?: string | null,
          postalCode?: string | null,
          state?: string | null,
          street?: string | null,
        } | null,
        avgNrOfAnimals?: number | null,
        contact?:  {
          __typename: "Contact",
          id: string,
          name?: string | null,
          relation?: string | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          currency?: string | null,
          currentBudget?: number | null,
          id?: string | null,
          initialBudget?: number | null,
          name?: string | null,
        } | null,
        groups?: Array< SpeciesGroup > | null,
        id?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      name?: string | null,
      phase?:  {
        __typename: "Phase",
        adequate?: number | null,
        deficient?: number | null,
        geneticLineage?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        geneticSupplier?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        id?: string | null,
        insufficient?: number | null,
        name?: string | null,
        optimum?: number | null,
        testReasonConfig?: string | null,
      } | null,
      phaseName?: string | null,
      problem?: string | null,
      problemArea?: string | null,
      productionSystem?: string | null,
      remarks?: string | null,
      samples?:  Array< {
        __typename: "Sample",
        age?: number | null,
        analytes?:  Array< {
          __typename: "Analyte",
          id?: string | null,
          name?: string | null,
        } > | null,
        animalId?: string | null,
        cardId?: string | null,
        collectionDate?: string | null,
        comment?: string | null,
        costCenter?:  {
          __typename: "CostCenter",
          id?: string | null,
          name?: string | null,
        } | null,
        dosage?: number | null,
        feedComposition?:  {
          __typename: "FeedComposition",
          caLevel?: number | null,
          pAvailable?: number | null,
          pLevel?: number | null,
          phytase?: number | null,
          phytaseUnit?: string | null,
        } | null,
        geneticLineage?: string | null,
        geneticSupplier?: string | null,
        id?: string | null,
        parity?: string | null,
        phase?:  {
          __typename: "Phase",
          adequate?: number | null,
          deficient?: number | null,
          id?: string | null,
          insufficient?: number | null,
          name?: string | null,
          optimum?: number | null,
          testReasonConfig?: string | null,
        } | null,
        problemAnswer?: boolean | null,
        productCategory?:  {
          __typename: "ProductCategory",
          id?: string | null,
          name?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
        } | null,
        results?:  Array< {
          __typename: "SampleResults",
          analysis?: string | null,
          analysisUnit?: string | null,
          evaluation?: string | null,
          expected?: number | null,
          id?: string | null,
          line?: number | null,
          module?: string | null,
          outlier?: boolean | null,
          repetitions?: number | null,
          result?: number | null,
          unit?: string | null,
        } > | null,
        sex?: string | null,
        sowStage?: string | null,
        species?:  {
          __typename: "Species",
          enabled?: boolean | null,
          group?: SpeciesGroup | null,
          id?: string | null,
          name?: string | null,
        } | null,
        timeline?:  Array< {
          __typename: "SampleTimeline",
          comments?: string | null,
          date?: string | null,
          description?: string | null,
          id?: string | null,
          order?: number | null,
          status?: string | null,
        } > | null,
      } > | null,
      specificPhase?: string | null,
      specificProblemArea?: string | null,
      takenBy?: string | null,
      testReason?: string | null,
      testReasonProblem?: string | null,
    } | null,
  },
};

export type AddSampleRequestPoultryMutationVariables = {
  input: SamplePoultryRequestInput,
};


export type EditSampleRequestMutationVariables = {
  input: SampleRequestEditInput,
};

export type EditSampleRequestMutation = {
  editSampleRequest:  {
    __typename: "SampleRequestResponse",
    sampleRequest?:  {
      __typename: "SampleRequest",
      average?: number | null,
      barn?: string | null,
      collectionDate?: string | null,
      comment?: string | null,
      d3Level?: number | null,
      housingSystem?: string | null,
      id?: string | null,
      location?:  {
        __typename: "Location",
        address?:  {
          __typename: "Address",
          city?: string | null,
          number?: string | null,
          postalCode?: string | null,
          state?: string | null,
          street?: string | null,
        } | null,
        avgNrOfAnimals?: number | null,
        contact?:  {
          __typename: "Contact",
          id: string,
          name?: string | null,
          relation?: string | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          currency?: string | null,
          currentBudget?: number | null,
          id?: string | null,
          initialBudget?: number | null,
          name?: string | null,
        } | null,
        groups?: Array< SpeciesGroup > | null,
        id?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      name?: string | null,
      phase?:  {
        __typename: "Phase",
        adequate?: number | null,
        deficient?: number | null,
        geneticLineage?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        geneticSupplier?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        id?: string | null,
        insufficient?: number | null,
        name?: string | null,
        optimum?: number | null,
        testReasonConfig?: string | null,
      } | null,
      phaseName?: string | null,
      problem?: string | null,
      problemArea?: string | null,
      productionSystem?: string | null,
      remarks?: string | null,
      samples?:  Array< {
        __typename: "Sample",
        age?: number | null,
        analytes?:  Array< {
          __typename: "Analyte",
          id?: string | null,
          name?: string | null,
        } > | null,
        animalId?: string | null,
        cardId?: string | null,
        collectionDate?: string | null,
        comment?: string | null,
        costCenter?:  {
          __typename: "CostCenter",
          id?: string | null,
          name?: string | null,
        } | null,
        dosage?: number | null,
        feedComposition?:  {
          __typename: "FeedComposition",
          caLevel?: number | null,
          pAvailable?: number | null,
          pLevel?: number | null,
          phytase?: number | null,
          phytaseUnit?: string | null,
        } | null,
        geneticLineage?: string | null,
        geneticSupplier?: string | null,
        id?: string | null,
        parity?: string | null,
        phase?:  {
          __typename: "Phase",
          adequate?: number | null,
          deficient?: number | null,
          id?: string | null,
          insufficient?: number | null,
          name?: string | null,
          optimum?: number | null,
          testReasonConfig?: string | null,
        } | null,
        problemAnswer?: boolean | null,
        productCategory?:  {
          __typename: "ProductCategory",
          id?: string | null,
          name?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
        } | null,
        results?:  Array< {
          __typename: "SampleResults",
          analysis?: string | null,
          analysisUnit?: string | null,
          evaluation?: string | null,
          expected?: number | null,
          id?: string | null,
          line?: number | null,
          module?: string | null,
          outlier?: boolean | null,
          repetitions?: number | null,
          result?: number | null,
          unit?: string | null,
        } > | null,
        sex?: string | null,
        sowStage?: string | null,
        species?:  {
          __typename: "Species",
          enabled?: boolean | null,
          group?: SpeciesGroup | null,
          id?: string | null,
          name?: string | null,
        } | null,
        timeline?:  Array< {
          __typename: "SampleTimeline",
          comments?: string | null,
          date?: string | null,
          description?: string | null,
          id?: string | null,
          order?: number | null,
          status?: string | null,
        } > | null,
      } > | null,
      specificPhase?: string | null,
      specificProblemArea?: string | null,
      takenBy?: string | null,
      testReason?: string | null,
      testReasonProblem?: string | null,
    } | null,
  },
};

export type UpdateNotificationReadStatusMutationVariables = {
  input: NotificationInput,
};

export type UpdateNotificationReadStatusMutation = {
  updateNotificationReadStatus:  {
    __typename: "NotificationResponse",
    notification?:  {
      __typename: "Notification",
      createdAt?: string | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      description?: string | null,
      id?: string | null,
      messageType?: string | null,
      readBy?: string | null,
      referenceId?: string | null,
      source?: Source | null,
      status?: string | null,
      transportProvider?: string | null,
    } | null,
  },
};

export type CheckCardIdQueryVariables = {
  cardId: string,
};

export type CheckCardIdQuery = {
  checkCardId:  {
    __typename: "ScanResult",
    isAvailable: boolean,
  },
};

export type GetCountriesQueryVariables = {
};

export type GetCountriesQuery = {
  getCountries:  {
    __typename: "CountriesResponse",
    countries?:  Array< {
      __typename: "Country",
      abbreviation?: string | null,
      id?: string | null,
      name?: string | null,
    } > | null,
  },
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    currency?: string | null,
    currentBudget?: number | null,
    dsmUnit?:  {
      __typename: "DsmUnit",
      id?: string | null,
      name?: string | null,
    } | null,
    id?: string | null,
    initialBudget?: number | null,
    locations?:  Array< {
      __typename: "Location",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      avgNrOfAnimals?: number | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        relation?: string | null,
      } | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      groups?: Array< SpeciesGroup > | null,
      id?: string | null,
      name?: string | null,
      phone?: string | null,
    } > | null,
    name?: string | null,
  } | null,
};

export type GetCustomersForUserQueryVariables = {
};

export type GetCustomersForUserQuery = {
  getCustomersForUser:  {
    __typename: "CustomersResponse",
    customers?:  Array< {
      __typename: "BaseCustomer",
      currency?: string | null,
      currentBudget?: number | null,
      dsmUnitId?: string | null,
      id?: string | null,
      name?: string | null,
      numberOfFarms?: number | null,
      numberOfKits?: number | null,
      numberOfRequests?: number | null,
    } > | null,
  },
};

export type GetKitRequestQueryVariables = {
  kitRequestId: string,
};

export type GetKitRequestQuery = {
  getKitRequest:  {
    __typename: "KitRequestResponse",
    kitRequest?:  {
      __typename: "KitRequest",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      contactName?: string | null,
      id?: string | null,
      location?:  {
        __typename: "BaseLocation",
        address?:  {
          __typename: "Address",
          city?: string | null,
          number?: string | null,
          postalCode?: string | null,
          state?: string | null,
          street?: string | null,
        } | null,
        avgNrOfAnimals?: number | null,
        contact?:  {
          __typename: "Contact",
          id: string,
          name?: string | null,
          relation?: string | null,
        } | null,
        groups?: Array< SpeciesGroup > | null,
        id?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      orders?:  Array< {
        __typename: "KitOrder",
        amount?: number | null,
        createdDate?: string | null,
        id?: string | null,
        kit?:  {
          __typename: "Kit",
          currency?: string | null,
          id?: string | null,
          name?: string | null,
          priceEx?: number | null,
          vatPercentage?: number | null,
        } | null,
        status: KitOrderStatus,
        trackAndTrace?: string | null,
        transportProvider?: string | null,
      } > | null,
      sentToLocation?: boolean | null,
    } | null,
  },
};

export type GetKitRequestTableQueryVariables = {
  customerId?: string | null,
  input: KitRequestTableInput,
};

export type GetKitRequestTableQuery = {
  getKitRequestTable:  {
    __typename: "KitRequestTable",
    total: number,
    itemsPerPage: number,
    pages?:  Array< {
      __typename: "Page",
      page?: number | null,
      token?: string | null,
    } > | null,
    rows?:  Array< {
      __typename: "KitRequestTableItem",
      createdDateTime?: string | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      kitRequestId?: string | null,
      locationName?: string | null,
      orderNumber?: number | null,
      status?: KitOrderStatus | null,
      totalAmount?: number | null,
      trackAndTrace?: string | null,
    } > | null,
    sortBy: KitRequestTableSortProperties,
    sortDirection: SortDirection,
  },
};

export type GetLocationQueryVariables = {
  locationId: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "LocationResponse",
    location?:  {
      __typename: "Location",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      avgNrOfAnimals?: number | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        relation?: string | null,
      } | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      groups?: Array< SpeciesGroup > | null,
      id?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
  } | null,
};

export type GetLocationsForCustomerQueryVariables = {
  customerId: string,
};

export type GetLocationsForCustomerQuery = {
  getLocationsForCustomer:  {
    __typename: "LocationsResponse",
    locations?:  Array< {
      __typename: "BaseLocation",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      avgNrOfAnimals?: number | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        relation?: string | null,
      } | null,
      groups?: Array< SpeciesGroup > | null,
      id?: string | null,
      name?: string | null,
      phone?: string | null,
    } > | null,
  },
};

export type LocationsFilterInput = {
  query?: string;
  limit?: number;
  page?: number;
}

export type GetLocationsQueryVariables = {
  customerId: string,
  filters?: LocationsFilterInput | null,
}

export type GetLocationsQuery = {
  getLocations:  {
    __typename: "LocationsTableResponse",
    locations?:  Array< {
      __typename: "BaseLocation",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?:  {
          __typename: "Country",
          abbreviation?: string | null,
          id?: string | null,
          name?: string | null,
        } | null,
        number?: string | null,
        postalCode?: string | null,
        state?: string | null,
        street?: string | null,
      } | null,
      avgNrOfAnimals?: number | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        relation?: string | null,
      } | null,
      groups?: Array< SpeciesGroup > | null,
      id?: string | null,
      name?: string | null,
      phone?: string | null,
    } >,
    totalItems?: number,
    totalPages?: number,
  },
};

export type GetNotificationsForCustomerQueryVariables = {
  customerId: string,
};

export type GetNotificationsForCustomerQuery = {
  getNotificationsForCustomer:  {
    __typename: "NotificationsResponse",
    notifications?:  Array< {
      __typename: "Notification",
      createdAt?: string | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      description?: string | null,
      id?: string | null,
      messageType?: string | null,
      readBy?: string | null,
      referenceId?: string | null,
      source?: Source | null,
      status?: string | null,
      transportProvider?: string | null,
    } > | null,
  },
};

export type GetPhaseForSpeciesQueryVariables = {
  speciesId?: string | null,
};

export type GetPhaseForSpeciesQuery = {
  getPhaseForSpecies:  {
    __typename: "PhasesResponse",
    phases?:  Array< {
      __typename: "Phase",
      adequate?: number | null,
      deficient?: number | null,
      geneticLineage?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      geneticSupplier?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      id?: string | null,
      insufficient?: number | null,
      name?: string | null,
      optimum?: number | null,
      testReasonConfig?: string | null,
    } > | null,
  },
};

export type GetProductCompetitorsQueryVariables = {
  customerId?: string | null,
  productTypeId?: string | null,
};

export type GetProductCompetitorsQuery = {
  getProductCompetitors:  {
    __typename: "ProductCompetitorsResponse",
    competitors?:  Array< {
      __typename: "ProductCompetitor",
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      id?: string | null,
      name?: string | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
      } | null,
      sampleType?:  {
        __typename: "SampleType",
        id?: string | null,
        name?: string | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
        } | null,
      } | null,
    } > | null,
  },
};

export type GetSampleRequestQueryVariables = {
  sampleRequestId: string,
};

export type GetSampleRequestQuery = {
  getSampleRequest:  {
    __typename: "SampleRequestResponse",
    sampleRequest?:  {
      __typename: "SampleRequest",
      average?: number | null,
      barn?: string | null,
      collectionDate?: string | null,
      comment?: string | null,
      d3Level?: number | null,
      housingSystem?: string | null,
      id?: string | null,
      location?:  {
        __typename: "Location",
        address?:  {
          __typename: "Address",
          city?: string | null,
          number?: string | null,
          postalCode?: string | null,
          state?: string | null,
          street?: string | null,
        } | null,
        avgNrOfAnimals?: number | null,
        contact?:  {
          __typename: "Contact",
          id: string,
          name?: string | null,
          relation?: string | null,
        } | null,
        customer?:  {
          __typename: "Customer",
          currency?: string | null,
          currentBudget?: number | null,
          id?: string | null,
          initialBudget?: number | null,
          name?: string | null,
        } | null,
        groups?: Array< SpeciesGroup > | null,
        id?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      name?: string | null,
      phase?:  {
        __typename: "Phase",
        adequate?: number | null,
        deficient?: number | null,
        geneticLineage?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        geneticSupplier?:  Array< {
          __typename: "Genetic",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        id?: string | null,
        insufficient?: number | null,
        name?: string | null,
        optimum?: number | null,
        testReasonConfig?: string | null,
      } | null,
      phaseName?: string | null,
      problem?: string | null,
      problemArea?: string | null,
      productionSystem?: string | null,
      remarks?: string | null,
      samples?:  Array< {
        __typename: "Sample",
        age?: number | null,
        analytes?:  Array< {
          __typename: "Analyte",
          id?: string | null,
          name?: string | null,
        } > | null,
        animalId?: string | null,
        cardId?: string | null,
        collectionDate?: string | null,
        comment?: string | null,
        costCenter?:  {
          __typename: "CostCenter",
          id?: string | null,
          name?: string | null,
        } | null,
        dosage?: number | null,
        feedComposition?:  {
          __typename: "FeedComposition",
          caLevel?: number | null,
          pAvailable?: number | null,
          pLevel?: number | null,
          phytase?: number | null,
          phytaseUnit?: string | null,
        } | null,
        geneticLineage?: string | null,
        geneticSupplier?: string | null,
        id?: string | null,
        parity?: string | null,
        phase?:  {
          __typename: "Phase",
          adequate?: number | null,
          deficient?: number | null,
          id?: string | null,
          insufficient?: number | null,
          name?: string | null,
          optimum?: number | null,
          testReasonConfig?: string | null,
        } | null,
        problemAnswer?: boolean | null,
        productCategory?:  {
          __typename: "ProductCategory",
          id?: string | null,
          name?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
        } | null,
        results?:  Array< {
          __typename: "SampleResults",
          analysis?: string | null,
          analysisUnit?: string | null,
          evaluation?: string | null,
          expected?: number | null,
          id?: string | null,
          line?: number | null,
          module?: string | null,
          outlier?: boolean | null,
          repetitions?: number | null,
          result?: number | null,
          unit?: string | null,
        } > | null,
        sex?: string | null,
        sowStage?: string | null,
        species?:  {
          __typename: "Species",
          enabled?: boolean | null,
          group?: SpeciesGroup | null,
          id?: string | null,
          name?: string | null,
        } | null,
        timeline?:  Array< {
          __typename: "SampleTimeline",
          comments?: string | null,
          date?: string | null,
          description?: string | null,
          id?: string | null,
          order?: number | null,
          status?: string | null,
        } > | null,
      } > | null,
      specificPhase?: string | null,
      specificProblemArea?: string | null,
      takenBy?: string | null,
      testReason?: string | null,
      testReasonProblem?: string | null,
    } | null,
  },
};

export type GetSampleResultCalculationsForCustomerQueryVariables = {
  customerId: string,
  phaseId?: string | null,
};

export type GetSampleResultCalculationsForCustomerQuery = {
  getSampleResultCalculationsForCustomer:  {
    __typename: "SampleResultCalculations",
    adequate?: number | null,
    availablePhases?:  Array< {
      __typename: "BasePhase",
      id?: string | null,
      name?: string | null,
    } > | null,
    deficient?: number | null,
    insufficient?: number | null,
    optimum?: number | null,
    selectedPhase?:  {
      __typename: "Phase",
      adequate?: number | null,
      deficient?: number | null,
      geneticLineage?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      geneticSupplier?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      id?: string | null,
      insufficient?: number | null,
      name?: string | null,
      optimum?: number | null,
      testReasonConfig?: string | null,
    } | null,
    totalAverage?: number | null,
  },
};

export type GetSampleResultCalculationsForLocationQueryVariables = {
  locationId: string,
  phaseId?: string | null,
};

export type GetSampleResultCalculationsForLocationQuery = {
  getSampleResultCalculationsForLocation:  {
    __typename: "SampleResultCalculations",
    adequate?: number | null,
    availablePhases?:  Array< {
      __typename: "BasePhase",
      id?: string | null,
      name?: string | null,
    } > | null,
    deficient?: number | null,
    insufficient?: number | null,
    optimum?: number | null,
    selectedPhase?:  {
      __typename: "Phase",
      adequate?: number | null,
      deficient?: number | null,
      geneticLineage?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      geneticSupplier?:  Array< {
        __typename: "Genetic",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      id?: string | null,
      insufficient?: number | null,
      name?: string | null,
      optimum?: number | null,
      testReasonConfig?: string | null,
    } | null,
    totalAverage?: number | null,
  },
};

export type GetSampleResultTableForCustomerQueryVariables = {
  customerId?: string | null,
  input: SampleResultTableInput,
};

export type GetSampleResultTableForCustomerQuery = {
  getSampleResultTableForCustomer:  {
    __typename: "SampleResultTable",
    total: number,
    itemsPerPage: number,
    pages?:  Array< {
      __typename: "Page",
      page?: number | null,
      token?: string | null,
    } > | null,
    rows?:  Array< {
      __typename: "SampleResultTableItem",
      average?: number | null,
      collectionDate?: string | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      description?: string | null,
      locationId?: string | null,
      locationName?: string | null,
      name?: string | null,
      phaseName?: string | null,
      sampleRequestId?: string | null,
      status?: string | null,
    } > | null,
    sortBy: SampleResultTableSortProperties,
    sortDirection: SortDirection,
  },
};

export type GetSampleResultTableForLocationQueryVariables = {
  input: SampleResultTableInput,
  locationId: string,
};

export type GetSampleResultTableForLocationQuery = {
  getSampleResultTableForLocation:  {
    __typename: "SampleResultTable",
    total: number,
    itemsPerPage: number,
    pages?:  Array< {
      __typename: "Page",
      page?: number | null,
      token?: string | null,
    } > | null,
    rows?:  Array< {
      __typename: "SampleResultTableItem",
      average?: number | null,
      collectionDate?: string | null,
      customer?:  {
        __typename: "Customer",
        currency?: string | null,
        currentBudget?: number | null,
        dsmUnit?:  {
          __typename: "DsmUnit",
          id?: string | null,
          name?: string | null,
        } | null,
        id?: string | null,
        initialBudget?: number | null,
        locations?:  Array< {
          __typename: "Location",
          avgNrOfAnimals?: number | null,
          groups?: Array< SpeciesGroup > | null,
          id?: string | null,
          name?: string | null,
          phone?: string | null,
        } > | null,
        name?: string | null,
      } | null,
      description?: string | null,
      locationId?: string | null,
      locationName?: string | null,
      name?: string | null,
      phaseName?: string | null,
      sampleRequestId?: string | null,
      status?: string | null,
    } > | null,
    sortBy: SampleResultTableSortProperties,
    sortDirection: SortDirection,
  },
};

export type GetSpeciesQueryVariables = {
  group?: SpeciesGroup | null,
};

export type GetSpeciesQuery = {
  getSpecies:  {
    __typename: "SpeciesResponse",
    species?:  Array< {
      __typename: "BaseSpecies",
      enabled?: boolean | null,
      group?: SpeciesGroup | null,
      id?: string | null,
      name?: string | null,
    } > | null,
  },
};

export type GetAdminExport = {
  getAdminExport:  {
    __typename: "AdminExportResponse",
    url?: string | null
  }
};

export type GetAdminExportVariables = {
};