import { IconProps } from "../../types";

const AnalysisIcon = ({width = "89", height = "89"}: IconProps) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 49H45" stroke="black" stroke-width="4" stroke-linecap="round"/>
    <circle cx="29.5" cy="25.5" r="13.5" stroke="black" stroke-width="2" stroke-dasharray="3.16 3.16"/>
    <path d="M13 59H45" stroke="black" stroke-width="4" stroke-linecap="round"/>
    <rect x="2" y="2" width="54" height="67" rx="6" stroke="black" stroke-width="4"/>
    <circle cx="29.5" cy="25.5" r="6.5" fill="#9D2835"/>
  </svg>
)

export default AnalysisIcon;