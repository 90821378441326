import { DsmRowActionClickEvent, DsmTableCustomEvent, DsmTableData } from "@dsm-dcs/design-system";
import { DsmButton, DsmEmptyState, DsmGrid, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/filter/searchInput/SearchInput";
import PageHeader from "../../components/pageHeader/PageHeader";
import { AuthContext } from "../../contexts/auth.context";
import { useLayout } from "../../contexts/layout.context";
import { BaseCustomer } from "../../models/API";
import { CustomersTableActions } from "../../models/enums/actions.enum";
import { Role } from "../../models/enums/role.enum";
import { getFilterCustomerFormSchema } from "../../models/forms/filter-customer-form.model";
import { routeTypes, routes } from "../../routes";
import { getCustomersForUser } from "../../services/user.service";
import styles from "./Customers.module.scss";

function Customers() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, handleCustomer, role } = useContext(AuthContext);
  const [schema] = useState(getFilterCustomerFormSchema());
  const { control, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  //State
  const pageSize = 10;
  const [isLoadingCustomers, setIsLoadingCustomers] = useState<boolean>(true);
  const [customers, setCustomers] = useState<BaseCustomer[]>([]);
  const [customersTable, setCustomersTable] = useState<DsmTableData>();
  const [customersCurrentPage, setCustomersCurrentPage] = useState<number>(1);
  const [customersPageCount, setCustomersPageCount] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const searchQuery = watch("searchQuery");

  //Effects
  useEffect(() => {
    handleCustomer(null);
    setDefaultPageData();
    getData();
  }, []);

  useEffect(() => {
    if (customersCurrentPage !== 1) {
      setCustomersCurrentPage(1);
      return;
    }
    filterCustomers();
  }, [searchQuery]);

  useEffect(() => {
    filterCustomers();
  }, [customersCurrentPage]);

  //Methods
  const setDefaultPageData = () => {
    setPageTitle(t("customers.page.title"));
    setCrumbs([{ title: t("customers.page.title"), type: routeTypes.customers }]);
    setValue("searchQuery", "");
    setCustomersCurrentPage(1);
  };

  const getData = async () => {
    const customers = await getCustomersForUser(setToast);
    setCustomers(customers);
    setTotalItems(customers.length);
    handleCustomersTableData(customers);
    setIsLoadingCustomers(false);
  };

  const filterCustomers = () => {
    const filteredCustomers = searchQuery ? customers.filter((_) => _.name?.toLowerCase().includes(searchQuery.toLowerCase())) : customers;
    handleCustomersTableData(filteredCustomers);
    setTotalItems(filteredCustomers.length);
  };

  const handleCustomersTableData = (tableCustomers: BaseCustomer[]) => {
    const startIndex = (customersCurrentPage - 1) * pageSize;
    const tableData = {
      columns: [
        { id: "name", label: t("customer.name") },
        { id: "farms", label: t("customer.farms") },
        { id: "kits", label: t("customer.kits") },
        { id: "requests", label: t("customer.requests") },
        { id: "budget", label: t("customer.budget") }
      ],
      data: tableCustomers
        .slice(startIndex, Math.min(startIndex + pageSize, tableCustomers.length))
        .sort((a, b) => {
          return (a.name || "").localeCompare(b.name || "");
        })
        .map((_) => {
          return {
            isClickable: true,
            actions: [
              {
                type: CustomersTableActions.CreateSampleRequest,
                icon: "development/qr-code-02",
                title: t("sample-request-create.page.title")
              },
              {
                type: CustomersTableActions.CreateFarm,
                icon: "maps-travel/map-02",
                title: t("farm-create.page.title")
              }
            ],
            id: _.id || "",
            cells: [
              { headerText: _.name || "" },
              { value: _.numberOfFarms?.toString() || "" },
              { value: _.numberOfKits?.toString() || "" },
              { value: _.numberOfRequests?.toString() || "" },
              { value: getBudget(_) }
            ]
          };
        })
    };

    if (role === Role.Admin) {
      tableData.data.forEach((row) => {
        row.actions.splice(0, 0, {
          type: CustomersTableActions.CreateKitRequest,
          icon: "finance-ecommerce/shopping-cart-03",
          title: t("order-new-kit.page.title")
        });
      });
    }

    setCustomersPageCount(Math.ceil(tableCustomers.length / pageSize));
    setCustomersTable(tableData);
  };

  const getBudget = (customer: BaseCustomer) => {
    const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: customer.currency ?? "EUR" });
    return numberFormat.format(customer.currentBudget ?? 0);
  };

  const handleCustomersTableRowClick = (e: DsmTableCustomEvent<string>) => {
    handleCustomer(customers.find((_) => _.id === e.detail) ?? null);
    navigate(routes.customerDetails.replace(":customerId", e.detail));
  };

  const handleCustomersTableAction = (e: DsmTableCustomEvent<DsmRowActionClickEvent>) => {
    if (e.detail.action === CustomersTableActions.CreateKitRequest) {
      navigate(routes.order);
    } else if (e.detail.action === CustomersTableActions.CreateSampleRequest) {
      navigate(routes.sampleRequestCreate);
    } else if (e.detail.action === CustomersTableActions.CreateFarm) {
      navigate(routes.farmCreate);
    }
  };

  const handleCustomersPageChange = (page: number) => {
    setCustomersCurrentPage(page);
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader
        header={customer?.name ? customer.name : t("customers.page.title")}
        totalItems={totalItems}
        actionsSide={
          customer ? (
            <>
              <DsmButton variant="secondary" onClick={() => navigate(routes.farmCreate)}>
                {t("farm-create.page.title")}
              </DsmButton>
              {role === Role.Admin ? (
                <DsmButton variant="secondary" onClick={() => navigate(routes.order)}>
                  {t("order-new-kit.page.title")}
                </DsmButton>
              ) : (
                ""
              )}
              <DsmButton variant="secondary" onClick={() => navigate(routes.sampleRequestCreate)}>
                {t("sample-request-create.page.title")}
              </DsmButton>
            </>
          ) : null
        }
      ></PageHeader>
        <>
          {!isLoadingCustomers ? (
            <div className="filters">
              <div className="search">
                <SearchInput fieldName="searchQuery" control={control} placeholder={t("general.search")}></SearchInput>
              </div>
            </div>
          ) : null}
          <div className={styles.table}>
            {!isLoadingCustomers ? (
              <>
                {customersTable && customersTable.data.length > 0 ? (
                  <>
                    <DsmTable
                      data={customersTable}
                      onDsmRowClick={handleCustomersTableRowClick}
                      onDsmRowActionClick={handleCustomersTableAction}
                    ></DsmTable>
                    {customersPageCount > 1 ? (
                      <DsmPagination
                        currentPage={customersCurrentPage}
                        pageCount={customersPageCount}
                        onDsmChangePage={(e) => handleCustomersPageChange(e.detail)}
                      ></DsmPagination>
                    ) : null}
                  </>
                ) : (
                  <DsmEmptyState header={t("customers.empty.header")} icon="users/user-01"></DsmEmptyState>
                )}
              </>
            ) : (
              <DsmLoadingIndicator size="md"></DsmLoadingIndicator>
            )}
          </div>
        </>
    </DsmGrid>
  );
}

export default Customers;
