import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { DsmSelectOption } from "@dsm-dcs/design-system";
import { API, Auth } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import { BaseQuery } from "../graphql/interfaces";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import {
  BaseCustomer,
  Customer,
  GetCustomerQuery,
  GetCustomersForUserQuery,
  GetNotificationsForCustomerQuery,
  GetNotificationsForCustomerQueryVariables,
  Notification,
  UpdateNotificationReadStatusMutation,
  UpdateNotificationReadStatusMutationVariables
} from "../models/API";
import { IToast } from "../models/toast.model";
import { IUser } from "../models/user.model";

export const getCustomer = async (customerId: string, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Customer | null> => {
  try {
    const result = await API.graphql<GraphQLQuery<GetCustomerQuery>>({
      query: queries.getCustomer,
      variables: { id: customerId }
    });
    return result.data?.getCustomer || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading customers", error: errors && errors.length > 0 ? errors[0].message : "" });
    return null;
  }
};

export const getCustomerForUser = async (setToast: Dispatch<SetStateAction<IToast | null>>): Promise<BaseCustomer | null> => {
  try {
    const result = await API.graphql<GraphQLQuery<GetCustomersForUserQuery>>({
      query: queries.getCustomersForUser
    });
    return result.data?.getCustomersForUser?.customers?.[0] || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading customer", error: errors && errors.length > 0 ? errors[0].message : "" });
    return null;
  }
};

export const getCustomersForUser = async (setToast: Dispatch<SetStateAction<IToast | null>>): Promise<BaseCustomer[]> => {
  try {
    const result = await API.graphql<GraphQLQuery<GetCustomersForUserQuery>>({
      query: queries.getCustomersForUser
    });
    return result.data?.getCustomersForUser?.customers || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading customers", error: errors && errors.length > 0 ? errors[0].message : "" });
    return [];
  }
};

export const getCustomersForSelect = async (setToast: Dispatch<SetStateAction<IToast | null>>): Promise<DsmSelectOption[]> => {
  const customers = await getCustomersForUser(setToast);
  const countryOptions: DsmSelectOption[] = customers.map((customer) => {
    return {
      text: customer.name || "",
      value: customer.id || ""
    };
  });
  countryOptions.sort((a, b) => a.text.localeCompare(b.text));
  return countryOptions;
};

export const getUser = async (): Promise<IUser> => {
  const userData = await Auth.currentUserInfo();
  const userSession = await Auth.currentSession();

  let fullName = userData?.attributes
    ? [userData.attributes.given_name, userData.attributes.middle_name, userData.attributes.family_name].filter((_) => !!_).join(" ")
    : "";

  if ((!fullName || fullName === "") && userSession.getIdToken().payload.family_name) {
    fullName = userSession.getIdToken().payload.family_name;
  }

  const user: IUser = {
    fullName: fullName,
    firstName: userData?.attributes?.given_name || "",
    lastName: userData?.attributes?.family_name || "",
    email: userData?.attributes?.email || ""
  };
  return user;
};

export const getNotifications = async (customerId: string, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Notification[]> => {
  try {
    const request: BaseQuery<GetNotificationsForCustomerQueryVariables> = {
      query: queries.getNotificationsForCustomer,
      variables: { customerId }
    };
    const result = await API.graphql<GraphQLQuery<GetNotificationsForCustomerQuery>>(request);
    return result.data?.getNotificationsForCustomer?.notifications || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading notifications", error: errors && errors.length > 0 ? errors[0].message : "" });
    return [];
  }
};

export const updateNotificationReadStatus = async (
  notificationId: string,
  customerId: string,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<void> => {
  try {
    const request: BaseQuery<UpdateNotificationReadStatusMutationVariables> = {
      query: mutations.updateNotificationReadStatus,
      variables: { input: { customerId, id: notificationId } }
    };
    await API.graphql<GraphQLQuery<UpdateNotificationReadStatusMutation>>(request);
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while updating notification read status", error: errors && errors.length > 0 ? errors[0].message : "" });
  }
};
